<template>
  <el-dialog
  class="search-dialog dialog_flex"
  @close="onClose"
  :before-close="onClose"
  :visible="visible"
  :append-to-body="true"
  width="80%">
    <el-form>
      <el-row
      class="search-head"
      :gutter="26">
        <el-col :md="6">
          <b style="display: inline-block;"
          class="label">
          Votre recherche: 
          </b>
          <el-input 
          style="margin-top: 40px;"
          v-model="search">
            <svg xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            style="width: 30px;
            height: 40px;" 
            slot="suffix"
            version="1.1" viewBox="0 0 60 60">
              <g fill="#aaaaaa">
              <path
              d="M 37.1,34.6 48.45,45.9 44.55,49.8 33.2,38.5 34.05,37.6 32.5,36.05 q -3.15,2.05 -6.85,2.05 -5.3,0 -9.1,-3.8 -3.8,-3.75 -3.8,-9.1 0,-5.3 3.8,-9.1 3.8,-3.8 9.1,-3.8 5.3,0 9.1,3.8 3.75,3.75 3.75,9.1 0,5.2 -3.6,8.9 l 1.35,1.35 0.85,-0.85 M 15.85,25.2 q 0,4.05 2.9,6.95 2.85,2.85 6.9,2.85 4.05,0 6.95,-2.85 2.85,-2.9 2.85,-6.95 0,-4.05 -2.85,-6.9 -2.9,-2.9 -6.95,-2.9 -4.05,0 -6.9,2.9 -2.9,2.85 -2.9,6.9" />
              <path
              d="m 27.65,18.45 q 2.5,0.55 3.95,2.6 1.45,2.05 1.15,4.55 -0.15,1.65 -1.85,1.5 -1.75,-0.15 -1.55,-1.8 0.2,-1.4 -0.65,-2.4 -0.8,-0.95 -2.2,-1.25 -0.65,-0.15 -0.95,-0.85 -0.3,-0.7 -0.1,-1.35 0.25,-0.7 0.9,-0.95 l 1.3,-0.05" />
              </g>
            </svg>
          </el-input>
        </el-col>
  <!--     </el-row>
      <el-row
      style="margin-top: 10px;"
      :gutter="26"> -->
        <el-col :md="18">
          <b style="display: inline-block;"
          class="label">
          Filtrer par catégorie: 
          </b>
          <el-checkbox-group
          v-model="cat">
            <el-checkbox-button
            v-for="point_categorie in point_categories.list"
            :style="{
              'background-color': point_categorie.color,
              'color': point_categorie.color,
              'border-color': point_categorie.color 
            }"
            :label="point_categorie.id"
            :key="point_categorie.id">{{point_categorie.title}}</el-checkbox-button>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row
      class="main_flex"
      v-loading="loading"
      style="margin-top: 10px;"
      :gutter="0">
        <el-col :md="24"
        v-for="point in points"
        v-if="visible_cat[point.categorie] === true"
        :key="point.milieu_id+'-'+point.id"
        style="cursor: pointer;">
          <div
          @click="goto(point)"
          class="list_entry"
          v-html="point.title"></div>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import { EventBus } from '@/components/event_bus.js'

export default {
  name: 'SearchDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    point_categories: {
      type: Object
    },
  },
  data () {
    return {
      baseUrl: global.baseUrl,
      search: '',
      points: [],
      visible_cat: {},
      cat: [],
      loading: true
    }
  },
  watch: {
    search: function(val) {
      console.log('search', val)
      this.onSearch(val)
    },
    cat: function(val) {
      var vm = this

      vm.point_categories.list.forEach(function(c){
        vm.visible_cat[c.id] = false
      })

      vm.cat.forEach(function(c_id){
        vm.visible_cat[c_id] = true
      })

      console.log('onChange', val, vm.visible_cat)
    },
  },
  mounted () {
    var vm = this

    vm.point_categories.list.forEach(function(c){
      vm.cat.push(c.id)
      vm.visible_cat[c.id] = true
    })

    this.getAllPoints()
  },
  methods: {
    onClose: function() {
      // console.log('update:visible', false)
      this.$emit('update:visible', false)
    },
    goto: function(point) {
      console.log('goto point 1', point)
      this.$emit('goto', {
        milieu_id: point.milieu_id,
        point_id: point.id,
      })
      this.onClose()
    },
    onChange: function(val) {
      var vm = this

      vm.point_categories.list.forEach(function(c){
        vm.visible_cat[c.id] = false
      })

      vm.cat.forEach(function(c_id){
        vm.visible_cat[c_id] = true
      })

      console.log('onChange', val, vm.visible_cat)
    },
    onSearch: function(val){
      var vm = this

      this.loading = true
      this.$http.post(
        global.ajaxUrl+"?action=search_points", {
          search: val
        })
      .then(function(data){
        var resp = data.body

        console.log("resp", resp)
        this.points = resp.points
        this.loading = false
      },function(data){

      })
    },
    getAllPoints: function() {
      this.loading = true
      this.$http.post(
        global.ajaxUrl+"?action=get_all_points_entries", {

        })
      .then(function(data){
        var resp = data.body
        resp.points.forEach(function(p){
          p.id = Number(p.id)
          p.categorie = Number(p.categorie)
        })
        this.points = resp.points
        this.loading = false

      },function(data){

      })
    },
  }
}
</script>
<style type="text/css">

.search-dialog .el-checkbox-button.is-checked .el-checkbox-button__inner,
.search-dialog .el-checkbox-button__inner {
  background-color: transparent;
  border-width: 0 !important;
  color: #FFFFFF;
}

.search-dialog .el-checkbox-button.is-checked .el-checkbox-button__inner {
  color: #FFFFFF;
}

.search-dialog .el-checkbox-button:not(.is-checked) .el-checkbox-button__inner {
  color: inherit;
}

.search-dialog .el-checkbox-button {
  border: 2px solid #FFF !important;
  border-style: solid;
  overflow: hidden;
  transition: background .3s cubic-bezier(.645,.045,.355,1);
  width: 120px;
  height: 120px;
  line-height: 110px;
  text-align: center;
  margin: 0 5px;
  border-radius: 120px !important;
  box-shadow: 10px 10px 10px #0004;
  cursor: pointer;
}

.search-dialog .el-checkbox-button .el-checkbox-button__inner{
  border: 0 none !important;
  padding: 0;
  font-size: 20px;
  font-weight: bold; 
  box-shadow: 0 0 0 transparent;
}

.search-dialog .el-checkbox-button.is-checked {
  /*color: #FFFFFF !important;*/
}

.search-dialog .el-checkbox-button:not(.is-checked) {
  background-color: #FFF !important;
  /*color: transparent  !important;*/
}

.el-checkbox-group .el-checkbox-button:first-child {
  border-left-width: 1px;
  border-radius: 3px 0 0 3px;
}

.el-checkbox-group .el-checkbox-button:last-child {
  border-radius: 0 3px 3px 0;
}

.search-dialog .el-dialog {
  border-radius: 5px;
}

.search-dialog .el-dialog__header {
  padding: 0;
  height: 0;
}

.search-dialog .label {
  line-height: 40px;
  font-size: 20px;
  color: #fff;
}

.search-head {
  background-color: #8dc73f;
  padding: 10px 10px 20px 10px;
  border-radius: 5px 5px 0px 0px;
}

.search-dialog .main_flex {
  border: 1px solid #dcdfe6;
  border-radius: 3px;
}

.search-dialog .main_flex .el-col:nth-child(even) {
    background-color: #E8F4D9;
}

.search-dialog .list_entry {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  cursor: pointer;
}

.search-dialog.el-dialog__wrapper.dialog_flex .el-dialog__body {
  padding: 0 12px 12px;
  overflow: hidden;
}

.search-dialog .el-dialog__headerbtn {
  position: absolute;
  top: -16px;
  right: 12px;
  color: #fff;
  background-color: #af1624;
  font-size: 32px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  transition: background-color .2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 500;
}

.search-dialog .el-dialog__headerbtn:hover {
  background-color: #739e30;
}

.search-dialog  .el-icon-close {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

.search-dialog  .el-icon-close:before {
  content: "\E908";
}

</style>

