<template>
<transition name="fade2">
  <el-dialog
  title="Édition des paramètres"
  class="parameter-dialog dialog_flex"
  @close="onClose"
  :visible="true"
  :before-close="onClose"
  :append-to-body="false"
  width="80%">
    <el-row 
    style="height: 50px;"
    :gutter="26">
      <el-col :md="6">
        <div class="add-button admin-button"
        @mousedown.prevent
        style=""
        title=""
        @click="add">
          <i class="icon-plus"></i>
          <!-- <span>Ajouter un milieu</span> -->
        </div>
      </el-col>
    </el-row>
    <el-tabs 
    v-model="activeTab"
    type="border-card">
      <el-tab-pane 
      name="milieu_categories"
      label="Catégories des milieux">
        <el-form ref="form" label-width="80px">
          <el-row
          v-for="milieu_categorie in milieu_categories.list"
          v-if="milieu_categorie.deleted === 0"
          :key="milieu_categories.id"
          :gutter="26">
            <el-col :md="6">
              <el-form-item label="Titre">
                <el-input v-model="milieu_categorie.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="3">
              <el-form-item label="Picto">
                <div class="img-display"
                @click="choosePicto(milieu_categorie)">
                  <icon name="image"
                  class="color: #c33;"
                  scale="2"
                  v-if="parseInt(milieu_categorie.media_id) === 0"
                  ></icon>
                  <img v-else
                  :src="baseUrl+milieu_categorie.icon">
                </div>
              </el-form-item>
            </el-col>
            <el-col :md="13">
              <el-form-item label="Description">
                <el-input v-model="milieu_categorie.descr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="2">
              <div class="add-button admin-button"
              style="float: right;"
              @click="deleteMilieuCategorie(milieu_categorie)">
                <i class="icon-trash"></i>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane 
      name="point_categories"
      label="Catégories des points">
        <el-form ref="form" label-width="80px">
          <el-row 
          v-for="point_categorie in point_categories.list"
          v-if="point_categorie.deleted === 0"
          :key="point_categories.id"
          :gutter="26">
            <el-col :md="6">
              <el-form-item label="Titre">
                <el-input v-model="point_categorie.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="Couleur">
                <el-color-picker v-model="point_categorie.color"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <div class="add-button admin-button"
              style="float: right;"
              @click="deletePointCategorie(point_categorie)">
                <i class="icon-trash"></i>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane 
      name="licences"
      label="Licences">
        <el-form ref="form" label-width="100px">
          <el-row 
          v-for="licence in licences.list"
          v-if="licence.id > 0 && licence.deleted === 0"
          :key="licence.id"
          :gutter="26">
            <el-col :md="6">
              <el-form-item label="Nom">
                <el-input v-model="licence.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="Lien">
                <el-input v-model="licence.link"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item
              label="Téléchargeable">
                <el-switch
                  v-model="licence.free"
                  :inactive-value="0"
                  :active-value="1">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :md="3">
              <div class="add-button admin-button"
              style="float: right;"
              @click="deleteLicence(licence)">
                <i class="icon-trash"></i>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <el-button slot="footer" type="primary"
    style="width: 100%;"
    @click="onSave">OK</el-button>
  </el-dialog>
</transition>
</template>

<script>
import { EventBus } from '@/components/event_bus.js'

export default {
  name: 'PointDialog',
  props: {
    milieu_categories: {
      type: Object
    },
    point_categories: {
      type: Object
    },
    licences: {
      type: Object
    }
  },
  data () {
    return {
      baseUrl: global.baseUrl,
      activeTab: 'milieu_categories',
      milieu_categories_AI: global.milieu_categories_AI,
      point_categories_AI: global.point_categories_AI,
      licences_AI: global.licences_AI,
    }
  },
  mounted () {
    console.log('AI',
      global.milieu_categories_AI,
      global.point_categories_AI,
      global.licences_AI)
  },
  methods: {
    get_blank_milieu_categorie: function(){
      return {
        id: ++this.milieu_categories_AI,
        title: '',
        descr: '',
        icon: '',
        media_id: 0,
        new: 1,
        map_visible: 1,
        deleted: 0
      }
    },
    get_blank_point_categorie: function(){
      return {
        id: ++this.point_categories_AI,
        title: '',
        color: '#FF0000',
        new: 1,
        deleted: 0
      }
    },
    get_blank_licence: function(){
      return {
        id: ++this.licences_AI,
        title: '',
        link: '',
        free: 0,
        new: 1,
        deleted: 0
      }
    },
    add: function() {
      console.log('activeTab', this.activeTab)
      switch(this.activeTab){
        case 'milieu_categories':
          this.addMilieuCategorie()
        break;
        case 'point_categories':
          this.addPointCategorie()
        break;
        case 'licences':
          this.addLicence()
        break;
      }
    },
    addMilieuCategorie: function() {
      var new_milieu_categorie = this.get_blank_milieu_categorie()
      this.milieu_categories.list.push(new_milieu_categorie)
      this.milieu_categories.ref[new_milieu_categorie.id] = new_milieu_categorie
    },
    addPointCategorie: function() {
      var new_point_categorie = this.get_blank_point_categorie()
      this.point_categories.list.push(new_point_categorie)
      this.point_categories.ref[new_point_categorie.id] = new_point_categorie
    },
    addLicence: function() {
      var new_licence = this.get_blank_licence()
      this.licences.list.push(new_licence)
      this.licences.ref[new_licence.id] = new_licence
    },
    deleteMilieuCategorie: function(milieu_categorie) {
      milieu_categorie.deleted = 1
    },
    deletePointCategorie: function(point_categorie) {
      point_categorie.deleted = 1
    },
    deleteLicence: function(licence) {
      licence.deleted = 1
    },
    onClose: function() {
      this.$emit('close')
    },
    choosePicto: function(milieu_categorie){
      var vm = this

      EventBus.$emit('mediamanager', {
        format_filter: true,
        formats_list: {
          thumbnail: true
        },
        type_filter: true,
        multi_select: false,
        types_list: {
          image: true
        },
        current_file_id: milieu_categorie.media_id,
      })

      EventBus.$once('mediamanager_end', function(media) {
        console.log('media milieu_categorie', media)
        if(media) {
          milieu_categorie.media_id = media.media_id
          milieu_categorie.icon = media.file_path
        }
        else if(media === 0) {
          milieu_categorie.media_id = 0
          milieu_categorie.icon = ''
        }
      })
    },
    onSave: function(){
      switch(this.activeTab){
        case 'milieu_categories':
          this.saveMilieuCategories()
        break;
        case 'point_categories':
          this.savePointCategories()
        break;
        case 'licences':
          this.saveLicences()
        break;
      }
    },
    saveMilieuCategories: function(){
      this.$http.post(
        global.ajaxAdminUrl+"?action=save_milieu_categories",  {
          token: global.token,
          milieu_categories: this.milieu_categories.list
        })
      .then(function(data){
        var resp = data.body
        var new_ids =resp.new_ids
        var new_id
        var milieu_categorie

        for(var id in new_ids) {
          new_id = new_ids[id]
          milieu_categorie = this.milieu_categories.ref[id]
          milieu_categorie.id = new_id
          delete this.milieu_categories.ref[id]
          this.milieu_categories.ref[new_id] = milieu_categorie
          milieu_categorie.new = 0
        }

        this.$message({
          message: 'Catégories des milieux sauvegardées',
          type: 'success'
        })

      },function(data){

      })
    },
    savePointCategories: function(){
      this.$http.post(
        global.ajaxAdminUrl+"?action=save_point_categories",  {
          token: global.token,
          point_categories: this.point_categories.list
        })
      .then(function(data){
        var resp = data.body
        var new_ids =resp.new_ids
        var new_id
        var point_categorie

        for(var id in new_ids) {
          new_id = new_ids[id]
          point_categorie = this.point_categories.ref[id]
          point_categorie.id = new_id
          delete this.point_categories.ref[id]
          this.point_categories.ref[new_id] = point_categorie
          point_categorie.new = 0
        }

        this.$message({
          message: 'Catégories des points sauvegardées',
          type: 'success'
        })

        // this.onClose()

      },function(data){

      })
    },
    saveLicences: function(){
      this.$http.post(
        global.ajaxAdminUrl+"?action=save_licences",  {
          token: global.token,
          licences: this.licences.list
        })
      .then(function(data){
        var resp = data.body
        var new_ids =resp.new_ids
        var new_id
        var licence

        for(var id in new_ids) {
          new_id = new_ids[id]
          licence = this.licences.ref[id]
          licence.id = new_id
          delete this.licences.ref[id]
          this.licences.ref[new_id] = licence
          licence.new = 0
        }

        this.$message({
          message: 'Licences sauvegardées',
          type: 'success'
        })

        // this.onClose()

      },function(data){
        this.$message({
          message: 'Erreur de sauvegarde',
          type: 'error'
        })
      })
    }
  }
}
</script>
<style type="text/css">

.parameter-dialog .img-display {
  position: relative;
  width: 100%;
  height: 100%;
  width: 40px;
  height: 40px;
  padding: 2px;
  border: 1px solid #d8dce5;
  border-radius: 4px;
  margin: auto;
  margin-right: 5px;
  display: inline-block;
  float: left;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #c2c9d4;
  cursor: pointer;
  background-size: cover;
}

.parameter-dialog .img-display > img{
  max-width: 100%;
  max-height: 100%;
}

.parameter-dialog .img-display > svg{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.parameter-dialog .admin-button {
  width: 40px;
  height: 40px;
}

.el-color-dropdown {
  z-index: 9999 !important;
}

.parameter-dialog .el-color-picker {
  display: block;
}

.parameter-dialog .el-dialog__body {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.parameter-dialog .el-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: hidden;
}

.parameter-dialog .el-tabs__content {
  flex: 1;
  overflow: auto;
}

.parameter-dialog .el-tab-pane {
  overflow: visible;
}

</style>
