<template>
  <div ref="milieux-container"
  class="milieux-container"
  :class="{
    'left-slide': current_transition === 'slide-left',
    'right-slide': current_transition === 'slide-right',
    'no-transition': notransition
  }">
    <v-milieu 
    v-for="(milieu, milieu_index) in milieux"
    :key="milieu.id"
    class="milieu"
    :logged="logged"
    :drag_mode="drag_mode"
    :point_categories="point_categories"
    @backgroundLoad="backgroundLoad($event)"
    @pointOpen="pointOpen($event)"
    :class="{
      'slide-before': milieu_index === prev_index_tmp,
      'slide-center': milieu_index === current_index_tmp,
      'slide-after': milieu_index === next_index_tmp
    }"
    :milieu="milieu">

    </v-milieu>
  </div>
</template>
<script>
export default {
  props: {
    milieux: {
      type: Array
    },
    current_index: {
      type: Number
    },
    prev_index: {
      type: Number
    },
    next_index: {
      type: Number
    },
    logged: {
      type: Boolean,
      default: false
    },
    drag_mode: {
      type: Boolean,
      default: false
    },
    point_categories: {
      type: Object,
      default: false
    }
  },
  data () {
    return {
      notransition: true,
      current_index_tmp: 0,
      prev_index_tmp: null,
      next_index_tmp: null,
      current_transition: ''
    }
  },
  activated () {
    // this.getMilieuMap()
    this.init()
  },
  mounted () {
    // this.getMilieuMap()
    this.init()
  },
  methods: {
    init: function(){
      var vm = this
      var milieux_container = this.$refs["milieux-container"]
      
      console.log("milieux_container", milieux_container)
      this.current_index_tmp = this.current_index
      // this.prev_index_tmp = this.prev_index
      // this.next_index_tmp = this.next_index

      // requestAnimationFrame(function(){
      //   vm.notransition = false
      // })

      milieux_container.addEventListener(window.transitionEnd, function(e){
        if(e.target !== milieux_container) return

        vm.$emit("transitionend")
        vm.notransition = true
        requestAnimationFrame(function(){
          vm.current_index_tmp = vm.current_index
          // if(vm.prev_index_tmp){
          //   vm.current_index_tmp = vm.prev_index_tmp
            vm.prev_index_tmp = null
          // }
          // if(vm.next_index_tmp){
          //   vm.current_index_tmp = vm.next_index_tmp
            vm.next_index_tmp = null
          // }

          // vm.prev_index_tmp = vm.prev_index
          // vm.next_index_tmp = vm.next_index
          vm.current_transition = ''
          // requestAnimationFrame(function(){
            // vm.notransition = false
          // })
        })
      }, false);
    },
    pointOpen: function(point){
      this.$emit("pointOpen", point)
    },
    pointOpen: function(point){
      this.$emit("pointOpen", point)
    },
    backgroundLoad: function(milieu){
      this.$emit("backgroundLoad", milieu)
    },
    slideLeft: function(){
      this.setTransition('slide-left')
    },
    slideRight: function(){
      this.setTransition('slide-right')
    },
    setTransition: function(transition){
      var vm = this
      vm.notransition = false
      vm.$nextTick(function(){
        requestAnimationFrame(function(){
          vm.current_transition = transition
        })
      })
      // this.$emit("backgroundLoad", milieu)
    },
    slideLeftTo: function(index){
      this.prev_index_tmp = index
      this.setTransition('slide-left')
    },
    slideRightTo: function(index){
      this.next_index_tmp = index
      this.setTransition('slide-right')
    },
  }
}
</script>
<style type="text/css">

.milieux-container {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow-y: visible;
  overflow-x: visible;
  transition: transform 2s ease-in-out;
  background-color: #382112;
  background-color: #3A2312;
  transform: translateX(-100vw);
  will-change: transform;
}

.no-transition {
  transition-property: none !important;
}

.milieu {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  position: absolute;
  display: none;
}

.slide-before {
  top: 0;
  left: 0;
  display: block;
}

.slide-center {
  top: 0;
  left: 100vw !important;
  display: block;
}

.slide-after {
  top: 0;
  left: 200vw;
  display: block;
}

.middle-slide {
}

.milieux-container.left-slide {
  transform: translateX(0vw);
}

.milieux-container.right-slide {
  transform: translateX(-200vw);
}
</style>