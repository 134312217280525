<template>
<transition name="fade2">
  <el-dialog
  title="Édition du milieu"
  class="milieu-dialog"
  @close="onClose"
  :visible="true"
  :before-close="onClose"
  :append-to-body="false"
  width="80%">
    <el-form ref="form"
    :model="milieu" label-width="120px">

      <el-row :gutter="26">
      </el-row>
      <el-row :gutter="26">
        <el-col :md="6">
          <el-form-item label="Titre">
            <el-input v-model="milieu.title"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
            <el-form-item
            label="Catégorie">
              <el-select v-model="milieu.categorie"
              placeholder="Sélectionnez la catégorie">
                <el-option
                v-for="milieu_categorie in milieu_categories.list"
                v-if="milieu_categorie.deleted === 0"
                :key="milieu_categorie.id"
                :label="milieu_categorie.title"
                :value="milieu_categorie.id">
                </el-option>
              </el-select>
            </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item
          label="Publier">
            <el-switch
              v-model="milieu.publish"
              :inactive-value="0"
              :active-value="1">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <div class="add-button admin-button"
          style="float: right;"
          title="Ajouter un point d'intérêt"
          @click="deleteMilieu">
            <i class="icon-trash"></i>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="26">
        <el-col :md="4" style="height: 100%;">
            <el-form-item
            label="Son">
              <div class="img-display"
              @click="chooseSound">
                <icon name="music"
                :style="{
                  color: (parseInt(milieu.sounf_id) === 0)?'#c2c9d4':'#222'
                }"
                scale="3"
                ></icon>
              </div>
            </el-form-item>
        </el-col>
        <el-col :md="4" style="height: 100%;">
          <el-form-item
          label="Image de fond">
            <div class="img-display"
            @click="choosePhoto">
              <icon name="image"
              class="color: #c33;"
              scale="3"
              v-if="parseInt(milieu.photo_id) === 0"
              ></icon>
              <img v-else
              :src="photo_path">
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="4" style="height: 100%;">
          <el-form-item
          label="Logo partenaire">
            <div class="img-display"
            @click="chooseLogo">
              <icon name="image"
              class="color: #c33;"
              scale="3"
              v-if="parseInt(milieu.logo_id) === 0"
              ></icon>
              <img v-else
              :src="baseUrl+milieu.logo_path">
              <!-- :src="logo_path"> -->
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="12" style="height: 100%;">
          <el-form-item
          label="Lien partenaire">
            <el-input v-model="milieu.logo_url"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="6" style="height: 100%;">
          <el-form-item
          label="Crédits photo">
            <el-input v-model="milieu.credits_photo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6" style="height: 100%;">
          <el-form-item
          label="licence photo">
              <el-select v-model="milieu.licence_photo"
              clearable
              value-key="id"
              no-data-text="lol"
              placeholder="Licence photo">
                <el-option
                v-for="licence in licences.list"
                v-if="licence.deleted === 0 || licence.id == 0"
                :key="licence.id"
                :label="licence.title"
                :value="licence.id">
                </el-option>
              </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="6" style="height: 100%;">
          <el-form-item
          label="Crédits son">
            <el-input v-model="milieu.credits_sound"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6" style="height: 100%;">
          <el-form-item
          label="licence son">
              <el-select v-model="milieu.licence_sound"
              clearable
              value-key="id"
              no-data-text="lol"
              placeholder="Licence son">
                <el-option
                v-for="licence in licences.list"
                v-if="licence.deleted === 0 || licence.id == 0"
                :key="licence.id"
                :label="licence.title"
                :value="licence.id">
                </el-option>
              </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-tabs type="border-card">
        <el-tab-pane label="Description">
          <v-quill v-model="milieu.description"></v-quill>
        </el-tab-pane>
        <el-tab-pane label="Enjeux">
          <v-quill v-model="milieu.enjeux"></v-quill>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <el-button
    slot="footer"
    style="width: 100%;"
    :loading="saving"
    :disabled="saving"
    type="primary" @click="onSave">OK</el-button>
  </el-dialog>
</transition>
</template>

<script>
import { EventBus } from '@/components/event_bus.js'

export default {
  name: 'MilieuDialog',
  props: {
    milieu_categories: {
      type: Object
    },
    milieu: {
      type: Object
    },
    licences: {
      type: Object
    }
  },
  data () {
    return {
      baseUrl: global.baseUrl,
      saving: false,
      photo_path: '',
      logo_path: '',
    }
  },
  mounted () {
    if(parseInt(this.milieu.photo_id) > 0){
      this.photo_path = global.getThumbFormat(global.baseUrl + this.milieu.photo_path)
    }
    else {
      this.photo_path = ''
    }

    if(parseInt(this.milieu.logo_id) > 0){
      this.logo_path = global.getThumbFormat(global.baseUrl + this.milieu.logo_path)
    }
    else {
      this.logo_path = ''
    }

  },
  methods: {
    onClose: function() {
      // this.dialog_present = false
      this.$emit('close')
    },
    // onMediaSelect: function() {

    // },
    choosePhoto: function(){
      var vm = this
      // console.log('mediamanager bus send')
      // this.$refs.mediamanager.open()
      EventBus.$emit('mediamanager', {
        format_filter: true,
        formats_list: {
          milieu: true
        },
        type_filter: true,
        types_list: {
          image: true
        },
        current_file_id:vm.milieu.photo_id
      })

      EventBus.$once('mediamanager_end', function(media) {
        // console.log('mediamanager_end', media)
        if(media) {
          vm.milieu.photo_id = media.media_id
          vm.milieu.photo_path = media.file_path
          vm.milieu.photo_path_sized = global.getImageFormat(media.file_path, 'milieu')
          vm.photo_path = global.getThumbFormat(global.baseUrl + media.file_path)
        }
        else if(media === 0) {
          vm.milieu.photo_id = 0
          vm.milieu.photo_path_sized = ''
          vm.milieu.photo_path = ''
          vm.photo_path = ''
        }
      })
    },
    chooseSound: function(){
      var vm = this

      EventBus.$emit('mediamanager', {
        type_filter: true,
        types_list: {
          sound: true
        },
        current_file_id:vm.milieu.sound_id
      })

      EventBus.$once('mediamanager_end', function(media) {
        if(media) {
          vm.milieu.sound_id = media.media_id
          vm.milieu.sound_path = media.file_path
        }
        else if(media === 0) {
          vm.milieu.sound_id = 0
          vm.milieu.sound_path = ''
        }
      })
    },
    chooseLogo: function(){
      var vm = this

      EventBus.$emit('mediamanager', {
        format_filter: false,
        formats_list: {
          // milieu: true
        },
        type_filter: true,
        types_list: {
          image: true
        },
        current_file_id: vm.milieu.logo_id
      })

      EventBus.$once('mediamanager_end', function(media) {
        if(media) {
          vm.milieu.logo_id = media.media_id
          vm.milieu.logo_path = media.file_path
          vm.logo_path = global.getThumbFormat(global.baseUrl + vm.milieu.logo_path)
        }
        else if(media === 0) {
          vm.milieu.logo_id = 0
          vm.milieu.logo_path = ''
          vm.logo_path = ''
        }
      })
    },
    onSave: function(){
      var vm = this
      // console.log('milieu', vm.milieu.logo_id, vm.milieu.logo_path)
      this.saving = true
      this.$http.post(
        global.ajaxAdminUrl+"?action=set_milieu_data", {
          token: global.token,
          id: vm.milieu.id,
          photo_id: vm.milieu.photo_id,
          sound_id: vm.milieu.sound_id,
          logo_id: vm.milieu.logo_id,
          logo_url: vm.milieu.logo_url,
          categorie: vm.milieu.categorie,
          title: vm.milieu.title,
          description: vm.milieu.description,
          enjeux: vm.milieu.enjeux,
          credits_photo: vm.milieu.credits_photo,
          licence_photo: vm.milieu.licence_photo,
          credits_sound: vm.milieu.credits_sound,
          licence_sound: vm.milieu.licence_sound,
          publish: vm.milieu.publish
        })
      .then(function(data){
        var resp = data.body
        var milieu_id = Number(resp.milieu_id)

        // console.log('new_milieu_id', milieu_id)
        if(vm.milieu.id != milieu_id && milieu_id > 0) {
          vm.milieu.id = milieu_id
        }

        this.$message({
          message: 'Milieu sauvegardé',
          type: 'success'
        })

        vm.$emit('save', vm.milieu)
        this.saving = false
        vm.onClose()

      },function(data){
        this.saving = false
      })
    },
    deleteMilieu: function(){
      var vm = this
      this.$confirm('Êtes-vous sûr de vouloir supprimer le milieu?', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
      })
      .then(function(){
        vm.$emit('delete', vm.milieu)
        vm.onClose()
      }, function(data){
        // cancel
      })
    },
  }
}
</script>
<style type="text/css">
/*.img-display {
  width: 100%;
  height: 100%;
  width: 103px;
  height: 103px;
  border: 1px solid #d8dce5;
  border-radius: 4px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #c2c9d4;
  cursor: pointer;
}

.img-display > svg{
  margin: auto;
}

.img-display > img{
  max-width: 100%;
  max-height: 100%;
}*/

.milieu-dialog .img-display {
  position: relative;
  width: 100%;
  height: 100%;
  width: 103px;
  height: 103px;
  border: 1px solid #d8dce5;
  border-radius: 4px;
  margin: auto;
  margin-right: 5px;
  display: inline-block;
  float: left;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #c2c9d4;
  cursor: pointer;
  background-size: cover;
}

.milieu-dialog .img-display > img{
  max-width: 100%;
  max-height: 100%;
}

.milieu-dialog .img-display > svg{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.milieu-dialog .el-dialog__body {
  padding: 0 20px 20px;
}

.el-select-dropdown {
  z-index: 99999 !important;
}

.milieu-dialog .admin-button {
  width: 40px;
  height: 40px;
}

.milieu-dialog .el-dialog {
  height: 80%;
  min-height: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.milieu-dialog .el-dialog__body {
  overflow: hidden;
  flex: 1;
}

.milieu-dialog .el-tabs  {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.milieu-dialog .el-tabs__content {
  overflow: hidden;
  flex: 1;
}

.milieu-dialog .el-tab-pane {
  height: 100%;
}

.milieu-dialog .quill-wrap {
  height: 100%;
}

.milieu-dialog .quill-editor {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.milieu-dialog .ql-container {
  flex: 1;
  overflow: hidden;
}
</style>
