var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"milieux-container",staticClass:"milieux-container",class:{
  'left-slide': _vm.current_transition === 'slide-left',
  'right-slide': _vm.current_transition === 'slide-right',
  'no-transition': _vm.notransition
}},_vm._l((_vm.milieux),function(milieu,milieu_index){return _c('v-milieu',{key:milieu.id,staticClass:"milieu",class:{
    'slide-before': milieu_index === _vm.prev_index_tmp,
    'slide-center': milieu_index === _vm.current_index_tmp,
    'slide-after': milieu_index === _vm.next_index_tmp
  },attrs:{"logged":_vm.logged,"drag_mode":_vm.drag_mode,"point_categories":_vm.point_categories,"milieu":milieu},on:{"backgroundLoad":function($event){return _vm.backgroundLoad($event)},"pointOpen":function($event){return _vm.pointOpen($event)}}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }