const ctx = '@@InfiniteScroll';

var throttle = function (fn, delay) {
  var now, lastExec, timer, context, args; //eslint-disable-line

  var execute = function () {
    fn.apply(context, args);
    lastExec = now;
  };

  return function () {
    context = this;
    args = arguments;

    now = Date.now();

    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    if (lastExec) {
      var diff = delay - (now - lastExec);
      if (diff < 0) {
        execute();
      } else {
        timer = setTimeout(() => {
          execute();
        }, diff);
      }
    } else {
      execute();
    }
  };
};

var getScrollTop = function (element) {
  if (element === window) {
    return Math.max(window.pageYOffset || 0, document.documentElement.scrollTop);
  }

  return element.scrollTop;
};

var getComputedStyle = document.defaultView.getComputedStyle;

var getScrollEventTarget = function (element) {
  var currentNode = element;
  // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
  while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
    var overflowY = getComputedStyle(currentNode).overflowY;
    if (overflowY === 'scroll' || overflowY === 'auto') {
      return currentNode;
    }
    currentNode = currentNode.parentNode;
  }
  return window;
};

var getVisibleHeight = function (element) {
  if (element === window) {
    return document.documentElement.clientHeight;
  }

  return element.clientHeight;
};

var getElementTop = function (element) {
  if (element === window) {
    return getScrollTop(window);
  }
  return element.getBoundingClientRect().top + getScrollTop(window);
};

var isAttached = function (element) {
  var currentNode = element.parentNode;
  while (currentNode) {
    if (currentNode.tagName === 'HTML') {
      return true;
    }
    if (currentNode.nodeType === 11) {
      return false;
    }
    currentNode = currentNode.parentNode;
  }
  return false;
};

var doBind = function () {
  // console.log('doBind')
  if (this.binded) return; // eslint-disable-line
  this.binded = true;

  var $attrs = this.componentInstance.$attrs

  var directive = this;
  var element = directive.el;

  var throttleDelayExpr = $attrs['infinite-scroll-throttle-delay'];
  var throttleDelay = 200;
  if (throttleDelayExpr) {
    throttleDelay = Number(throttleDelayExpr);
    if (isNaN(throttleDelay) || throttleDelay < 0) {
      throttleDelay = 200;
    }
  }
  directive.throttleDelay = throttleDelay;

  directive.scrollEventTarget = getScrollEventTarget(element);
  directive.scrollListener = throttle(doCheck.bind(directive), directive.throttleDelay);
  directive.scrollEventTarget.addEventListener('scroll', directive.scrollListener);

  this.vm.$on('hook:beforeDestroy', function () {
    directive.scrollEventTarget.removeEventListener('scroll', directive.scrollListener);
  });

  var disabledExpr = $attrs['infinite-scroll-disabled'];
  var disabled = false;

  if (disabledExpr) {
    this.vm.$watch(disabledExpr, function(value) {
      directive.disabled = value;
      if (!value 
        // && directive.immediateCheck
        ) {
        doCheck.call(directive);
      }
    });
    disabled = Boolean(directive.vm[disabledExpr]);
  }
  directive.disabled = disabled;

  var distanceExpr = $attrs['infinite-scroll-distance'];
  var distance = 0;
  if (distanceExpr) {
    distance = Number(distanceExpr);
    if (isNaN(distance)) {
      distance = 0;
    }
  }
  directive.distance = distance;

  var immediateCheckExpr = $attrs['infinite-scroll-immediate-check'];
  var immediateCheck = true;
  // console.log('immediateCheckExpr', immediateCheckExpr)
  if (immediateCheckExpr) {
    // immediateCheck = Boolean(directive.vm[immediateCheckExpr]);
    immediateCheck = Boolean(immediateCheckExpr);
    // immediateCheck = true;
  }
  // else {
  //   immediateCheck = false;
  // }

  // console.log('immediateCheck', immediateCheck)
  directive.immediateCheck = immediateCheck;

  if (immediateCheck) {
    doCheck.call(directive);
  }

  // console.log('directive', directive)

  var eventName = $attrs['infinite-scroll-listen-for-event'];
  if (eventName) {
    // directive.vm
    this.componentInstance
    .$on(eventName, function() {
      doCheck.call(directive);
    });
  }

  // this.componentInstance
  // .$watch('infinite-scroll-disabled', function() {
  //   console.log('infinite-scroll-disabled')
  //   doCheck.call(directive);
  // });
};

var doCheckInit = function (force) {


}

var doCheck = function (force) {
  // console.log('doCheck')
  var t = this
  this.el[ctx].vm.$nextTick(function () {
    var scrollEventTarget = t.scrollEventTarget;
    var element = t.el;
    var distance = t.distance;

    if (force !== true && t.disabled) return; //eslint-disable-line
    var viewportScrollTop = getScrollTop(scrollEventTarget);
    var visibleHeight = getVisibleHeight(scrollEventTarget);
    var viewportBottom = viewportScrollTop + visibleHeight;
    var scrollHeight = scrollEventTarget.scrollHeight
    // var offsetHeight = scrollEventTarget.offsetHeight // same as visibleHeight
    // doc_height = doc.scrollHeight-doc.offsetHeight,
    // scroll_lim = doc_height*pct_lim,
    // console.log('visibleHeight', visibleHeight, viewportBottom, viewportScrollTop, scrollHeight)



    var shouldTrigger = false;
    if(visibleHeight === 0) return // element not rendered yet

    if (visibleHeight === scrollHeight) {
      shouldTrigger = true
      // console.log('shouldTrigger')
    }
    else if (scrollEventTarget === element) {
      // shouldTrigger = scrollHeight - viewportBottom <= distance;
      shouldTrigger = (visibleHeight + viewportScrollTop) / scrollHeight  >= distance;
      // console.log('(visibleHeight + viewportScrollTop) / scrollHeight', (visibleHeight + viewportScrollTop) / scrollHeight) 
      // console.log('scrollEventTarget.scrollHeight - viewportBottom', scrollEventTarget.scrollHeight, viewportBottom, visibleHeight) 
    } 
    // else {
    //   var elementBottom = getElementTop(element) - getElementTop(scrollEventTarget) + element.offsetHeight + viewportScrollTop;

    //   shouldTrigger = viewportBottom + distance >= elementBottom;
    // }

    if (shouldTrigger && t.expression) {
      t.expression();
    }
  });
};

function hook_mounted(el, args){
  // console.log("bind", el)
  el[ctx].vm.$nextTick(function () {
    if (isAttached(el)) {
      doBind.call(el[ctx], args);
    }

    el[ctx].bindTryCount = 0;

    var tryBind = function () {
      if (el[ctx].bindTryCount > 10) return; //eslint-disable-line
      el[ctx].bindTryCount++;
      if (isAttached(el)) {
        doBind.call(el[ctx], args);
      } else {
        setTimeout(tryBind, 50);
      }
    };

    tryBind();
  });
}

export default {
  bind(el, binding, vnode, t, q) {
    el[ctx] = {
      el,
      // vnode: vnode,
      componentInstance: vnode.componentInstance,
      vm: vnode.context,
      expression: binding.value
    };
    const args = arguments;
    // console.log("bind", el, binding)
    // console.log("vnode", vnode)
    // console.log("binding", binding)
    // console.log("t", t)
    // console.log("q", q)
    // console.log("args", args)
    if(el[ctx].vm._isMounted) {
      hook_mounted(el, args)
    }
    else{
      el[ctx].vm.$on('hook:mounted', function(){
        hook_mounted(el, args)
      });
    }
  },

  unbind(el) {
    if (el && el[ctx] && el[ctx].scrollEventTarget)
      el[ctx].scrollEventTarget.removeEventListener('scroll', el[ctx].scrollListener);
  }
};
