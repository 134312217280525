var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"milieu-container cover"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.milieu.show_markers && !_vm.milieu.loaded),expression:"milieu.show_markers && !milieu.loaded"}],ref:"milieu-inner",staticClass:"milieu-inner",class:{
    blur: _vm.milieu.blur
  },attrs:{"element-loading-background":"rgba(0, 0, 0, 0.25)"},on:{"mousedown":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"milieu-background blur"},[_c('img',{attrs:{"src":_vm.baseUrl + _vm.milieu.photo_path_sized}})]),_c('div',{ref:"milieu-background",staticClass:"milieu-background clear"},[_c('img',{attrs:{"src":_vm.baseUrl + _vm.milieu.photo_path_sized},on:{"load":_vm.backgroundLoad}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.milieu.show_markers)?_c('div',{staticClass:"interest-point-container"},_vm._l((_vm.milieu.points),function(point){return (_vm.milieu.loaded && point.deleted == 0)?_c('div',{ref:'milieu-point-'+point.id,refInFor:true,staticClass:"interest-point",style:({
                'background-image': 'url(\''+((point.media.length>0)?
                  point.media[0].thumb_url
                :'static/images/defaut.png')+'\')',
                'top': 'calc('+point.positionY+'% - 55px)',
                'left': 'calc('+point.positionX+'% - 55px)',
                'border-color': _vm.point_categories.ref[point.categorie].color
              }),attrs:{"data-point_id":point.id},on:{"click":function($event){$event.stopPropagation();return _vm.pointClick(point)},"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStart($event, point)}}},[_c('div',{staticClass:"point_title_container"},[_c('div',{staticClass:"point_title"},[_c('div',{staticClass:"point_title_cat"},[_c('span',{style:({
                        'background-color': _vm.point_categories.ref[point.categorie].color
                      }),domProps:{"innerHTML":_vm._s(_vm.point_categories.ref[point.categorie].title)}}),_c('div',{staticClass:"point_title_content"},[_c('span',{domProps:{"innerHTML":_vm._s(point.title)}})])])])])]):_vm._e()}),0):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }