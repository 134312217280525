import Vue from 'vue'
import Router from 'vue-router'
import MilieuxList from '@/pages/milieuxList'
import PointsList from '@/pages/pointsList'
import Main from '@/pages/main'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      // path: '/',
      path: '*',
      name: 'balades-virtuelles',
      components: {
        default: Main
      }
    },
    {
      path: '/milieu/:id',
      name: 'milieu',
      components: {
        default: Main
      }
    },
    {
      path: '/milieu/:id/points',
      name: 'PointsList',
      components: {
        default: PointsList
      }
    },
  ]
})
