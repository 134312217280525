<template>
  <el-container style="height: 100%">
    <el-main>
      <div class="container">
        <div class="toolbar" style="background-color: #f7f7f7;">
          <router-link to="/milieu/new">
            <el-button type="primary"
            @click="onAdd"
            icon0="el-icon-circle-plus-outline"
            style="flex: 0;">
              Nouveau milieu
            </el-button>
          </router-link>
        </div>
        <div class="list-group" v-if="milieux.length > 0">
          <router-link v-for="(milieu, r_index) in milieux"
          :key="milieu.id"
          :to="'/milieu/'+milieu.id"
          class="list-group-item justify-content-between">
            <span 
            class="title"
            v-html="milieu.title"></span>
            <router-link
            class="button"
            :to="'/milieu/'+milieu.id+'/points/'">
              <el-button
              size="mini"
              type="primary">
                <icon name="edit"></icon>
              </el-button>
            </router-link>
            <router-link
            class="button"
            :to="'/milieu/'+milieu.id">
              <el-button
              type="primary"
              size="mini"
              @click="onDel($event, milieu)">
                <icon name="map-marker"></icon>
              </el-button>
            </router-link>
            <el-button
            class="button"
            type="primary"
            size="mini"
            @click="onDel($event, r)">
              <icon name="trash"></icon>
            </el-button>
          </router-link>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  data () {
    return {
      milieu_id: 0,
      milieux: [],
      form: {
        title: 'MDR',
        descr: '123',
      },
      editorOption: {
        placeholder: "Description...",
        modules: {
          // toolbar: '#toolbar'
          toolbar: [
            [{ 'size': ['small', false, 'large'] }],
            [{ 'header': [ 1 ,  2 , 3 ,  4 ]}],  
            ['bold', 'italic'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link', 'image']
          ],
        }
      }
    }
  },
  activated () {
    this.getMilieuList()
  },
  mounted () {
    this.getMilieuList()
  },
  methods: {
    onAdd: function(e){
      console.log(this.$router)
      // this.$http.post(
      //   global.baseUrl+"main.php?action=get_milieu_list", {

      //   },{
      //     emulateJSON: true
      //   })
      // .then(function(data){
      //     var resp = data.body
      //     if(resp.ok){
      //       console.log(resp)
      //       vm.milieux = resp.milieux
      //     }
      //   }, function(data){

      //   });
    },
    onDel: function(e, milieu){

    },
    getMilieuList: function(){
      var vm = this
      
      this.$http.post(
        global.baseUrl+"admin.php?action=get_milieu_list", {

        },{
          emulateJSON: true
        })
      .then(function(data){
          var resp = data.body
          if(resp.ok){
            console.log(resp)
            vm.milieux = resp.milieux
          }
        }, function(data){

        });
    },
    save: function(){
      var post_data
      var action
      if(this.milieu_id > 0) {
        // post_data = {}
      }

      this.$http.post(
        global.baseUrl+"main.php?action=set_milieu", {
          milieu_id: this.milieu_id,
          title: this.form.title,
          descr: this.form.descr,
        }, 
        {
          emulateJSON: false
        }).then(function(data){
          var resp = data.body
          var new_milieu_id = parseInt(data.body.milieu_id)

          if(this.milieu_id !== new_milieu_id){
            this.$router.replace('/milieu/'+new_milieu_id)
          }
        }, function(data){
      });
    }
  }
}
</script>
<style type="text/css">

</style>