<template>
  <div id="hud-container"
  :class="{
    'hud-hidden': hidden
  }">
    <div class="hud-container-inner">
      <div id="nav-left-container">
        <div id="minimap-container"
        @mousedown.prevent
        @click="mapClick">
          <img src="icons/minimap.png">
          <div class="minimap-label">Accueil</div>
          <div class="minimap-marker-wrap">
            <div class="minimap-marker-inner">
              <div class="minimap-marker"
              v-if="current_milieu"
              :style="{
                left: current_milieu.positionX+'%',
                top: current_milieu.positionY+'%'
              }"></div>
            </div>
          </div>
        </div>
        <div class="animated-button-container">
          <v-animated_button
          @mouseover="help_show"
          @mouseout="help_hide"
          animated_src="icons/help.gif"
          origin_src="icons/help.png">
          </v-animated_button>
          <v-animated_button
          @click="fullscreen"
          animated_src="icons/fullscreen.gif"
          origin_src="icons/fullscreen.png">
          </v-animated_button>
          <v-animated_button
          @click="soundtoggle"
          animated_src="icons/sound.gif"
          origin_src="icons/sound.png"
          alt_src="icons/nosound.png"
          :alt_state="nosound">
          </v-animated_button>
        </div>
      </div>
      <div id="nav-middle-container">
        <div id="nav-left" class="arrow-button left"
        @mousedown.prevent
        @click="prevSlide">
          <i class="icon-arrow_left"></i>
        </div>
        <div id="nav-right" class="arrow-button right"
        @mousedown.prevent
        @click="nextSlide">
          <i class="icon-arrow_right"></i>
        </div>
      </div>
      <div class="nav-logo-container">
        <div v-if="current_milieu && current_milieu.logo_id > 0"
        style="float: left;"
        class="hud-logo logo-partenaire">
          <a :href="current_milieu.logo_url"
          target="_blank">
            <img :src="baseUrl + current_milieu.logo_path">
          </a>
        </div>
        <div
        style="float: right;"
        class="hud-logo">
          <a href="http://www.fne-midipyrenees.fr/agir-avec-nous_254.php"
          target="_blank">
            <img src="images/FNE_Logo_Midi-Pyrenees_RVB_petit.jpg">
            <div class="hud-logo-label">Soutenez-nous !</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HUD',
  props: {
    hidden: {
      type: Boolean,
      default: false
    },
    nosound: {
      type: Boolean,
      default: false
    },
    current_milieu: {
      type: Object
    },
    logo_partenaire_path: {
      type: String,
      default: ''
    },
    logo_partenaire_link: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      baseUrl: global.baseUrl,
    }
  },
  mounted: function() {
    
  },
  methods: {
    mapClick: function(){
      this.$emit('mapClick')
    },
    prevSlide: function(){
      this.$emit('prevSlide')
    },
    nextSlide: function(){
      this.$emit('nextSlide')
    },
    fullscreen: function(){
      // console.log('fullscreen')
      this.$emit('fullscreen')
    },
    soundtoggle: function(){
      // console.log('fullscreen')
      this.$emit('soundtoggle')
    },
    help_show: function(){
      // console.log('help_show')
      this.$emit('help_show')
    },
    help_hide: function(){
      this.$emit('help_hide')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/css">

#hud-container {
  width: 100vw;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateZ(0px);
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s cubic-bezier(.65,.05,.36,1);
  z-index: 3000;
  /*will-change: transform;*/
}

#hud-container.hud-hidden {
  transform: translateY(250px);
}

.hud-container-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

#nav-middle-container {
  margin: auto;
  width: 164px;
  height: 100%;
  position: relative;
}

.arrow-button {
  display: table-cell;
  display: inline-block;
  border: 2px solid #e1051b;
  border-radius: 100px;
  background-color: #fff;
  height: 72px;
  width: 72px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  text-align: center;
}

.arrow-button > i{
  color: #e1051b;
  font-size: 4.4rem;
}

.arrow-button img {
  display: block;
  height: 100%;
}

.arrow-button.left {
  left: 0;
}

.arrow-button.right {
  right: 0;
}

#nav-left-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.animated-button-container {
  
}

.animated-button {
  margin: 0 7px;
}

#minimap-container {
  cursor: pointer;
  width: 160px;
  margin: auto;
  margin-bottom: 15px;
  position: relative;
  border-radius: 100px;
}

#minimap-container:hover {
  box-shadow: 2px 5px 10px rgba(0,0,0,0.7);
}

#minimap-container > img {
  width: 100%;
  display: block;
}

.minimap-label {
  position: absolute;
  font-weight: 700;
  font-size: 15px;
  display: inline-block;
  background-color: #e1051b;
  color: #fff;
  padding: 0 10px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  line-height: 21px;
}

.nav-logo-container {
  position: absolute;
  bottom: 30px;
  right: 5px;
  height: 120px;
}

.hud-logo {
  box-shadow: 2px 5px 10px rgba(0,0,0,0.7);
  height: 100%;
  margin: 5px;
}

.hud-logo a,
.hud-logo img{
  height: 100%;
  display: block;
}

.hud-logo-label {
  font-weight: 700;
  font-size: 17px;
  display: block;
  color: #fff;
  line-height: 21px;
  white-space: nowrap;
  text-shadow: 0 0 1px #000, 0 0 3px #000;
  text-align: center;
}

.logo-partenaire {

}

.minimap-marker-wrap {
  position: absolute;
  top: 11px;
  left: 8px;
  right: 4px;
  bottom: 15px;
}

.minimap-marker-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.minimap-marker {
  position: absolute;
  background-color: #af1624;
  border: 4px solid #af1624;
  transform: rotate(45deg);
  opacity: 0.98;
}
</style>
