<template>
<div id="help-container" class="help-container vertical">
  <div class="help-element help-panel">
    <img src="icons/help-top-left-arrow.svg">
    <div class="help-text">
      Découvrez le milieu naturel, ses enjeux ainsi que
      le nom du photographe et les conditions de diffusion
    </div>
  </div>

  <div class="help-element help-home">
    <img src="icons/help-bottom-left-arrow.svg">
    <div class="help-text">
      Retrouvez le menu d'accueil
    </div>
  </div>

  <div class="help-element help-midipy">
    <img src="icons/help-bottom-right-arrow.svg">
    <div class="help-text">
      Accédez directement à la page de soutien de FNE Midi-Pyrénées
    </div>
  </div>

  <div class="help-element help-points">
    <div class="help-text">
      <span>
        En cliquant sur les points vous affichez
        des informations sur le milieu naturel
      </span>
      <div class="cat-container" v-for="cat in point_categories.list">
        <div class="cat-color"
        :style="{
          'background-color': cat.color
        }"></div>
        <div class="cat-text" v-html="cat.title"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Help',
  props: {
    point_categories: {
      type: Object
    },
  },
  data () {
    return {
      // msg: 'Welcome to Your Vue.js App'
    }
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/css">

.help-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 2500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px;
  padding-bottom: 50px;
  text-align: center;
}

.help-element {
  position: fixed;
}

.help-element img {
  float: left;
}

.help-text {
  display: inline-block;
  font-weight: bold;
  background-color: #19110b;
  color: #fff;
  padding: 3px 7px 5px;
  max-width: 400px;
  text-align: left;
  line-height: 1.2rem;
}

.help-text span {
  clear: both;
  display: block;
  padding-bottom: 5px;
}

.help-panel {
  top: 80px;
  left: 21.5vw;
}

.help-panel .help-text {
  width: 220px;
  margin-left: 20px;
  margin-top: 20px;
}

.help-home {
  bottom: 150px;
  left: 220px;
}

.help-home .help-text {
  margin-left: 20px;
}

.help-midipy {
  bottom: 200px;
  right: 80px;
}

.help-midipy img {
  float: right;
  margin-top: 30px;
}

.help-midipy .help-text {
  width: 165px;
  margin-right: 20px;
}

.help-points {
  top: 50%;
  left: 50%;
}

.help-points .help-text {
  width: 230px;
}

.help-container .cat-container {
  float: left;
  width: 50%;
  clear: right;
}

.help-container .cat-color {
  width: 20px;
  height: 14px;
  display: inline-block;
  float: left;
  margin-top: 3px;
  margin-right: 5px;
}

.help-container .cat-text {
  height: 20px;
  display: inline-block;
  font-weight: normal;
}
</style>
