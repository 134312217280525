<template>
  <div id="sound-button" class="animated-button"
  @mousedown.prevent
  @mouseover="onMouseover"
  @mouseout="onMouseout"
  @click="onClick">
    <img :src="src">
    <img class="animated-icon"
    :src="animated_src">
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    origin_src: {
      type: String
    },
    animated_src: {
      type: String
    },
    alt_src: {
      type: String
    },
    alt_state: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      src: ''
    }
  },
  // activated () {
  //   this.init()
  // },
  mounted () {
    this.init()
  },
  methods: {
    init: function(){
      this.src = (this.alt_state)?this.alt_src:this.origin_src
    },
    onMouseover: function(){
      this.$emit('mouseover')
      this.src = this.animated_src
    },
    onMouseout: function(){
      this.$emit('mouseout')
      this.src = (this.alt_state)?this.alt_src:this.origin_src
    },
    onClick: function(){
      this.$emit('click')
    },

  }
}
</script>
<style type="text/css">

.animated-button {
  cursor: pointer;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: relative;
  border: 2px solid #fff;
  border-radius: 100px;
  display: inline-block;
  user-select: none;
}

.animated-button > img {
  position: absolute;
  display: block;
  width: 100%;
  border-radius: 500px;
}

.animated-icon {
  position: absolute;
  opacity: 0;
}
</style>
