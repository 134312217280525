// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/fr'
// import Slideout from 'vue-slideout'
import VueResource from 'vue-resource'

import VueQuillEditor from 'vue-quill-editor'
import QuillComponent from '@/components/quill'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// import 'highlight.js/styles/default.css'
import 'highlightjs/styles/atom-one-light.css'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import HUD from '@/components/HUD'
import MapMilieu from '@/components/Map'
import animated_button from '@/components/animated_button'
import point from '@/components/point'
import Panel from '@/components/Panel'
import Milieux from '@/components/milieux'
import Milieu from '@/components/milieu'
import Help from '@/components/Help'
import Swiper from '@/components/Swiper'
import MilieuDialog from '@/components/MilieuDialog'
import PointDialog from '@/components/PointDialog'
import ParameterDialog from '@/components/ParameterDialog'
import SearchDialog from '@/components/SearchDialog'
import DownloadDialog from '@/components/DownloadDialog'
import { EventBus } from '@/components/event_bus.js'
import MediaManager from '@/components/mediaManager'
import InfiniteScroll from '@/components/InfiniteScroll.js'
import draggable from 'vuedraggable'

Vue.component('icon', Icon)

Vue.use(VueQuillEditor
  // ,{ default global options } 
)

if(window.location.hostname === 'localhost') {
  // global.baseUrl = 'http://localhost/fnepaca/balades-virtuelles/www/'
  global.baseUrl = 'http://localhost/fnepaca/balades-virtuelles/www_fne_aquitaine/'
  global.ajaxUrl = global.baseUrl+'main.php'
  global.ajaxAdminUrl = global.baseUrl+'admin_api.php'
}
else {
  // global.baseUrl = '/balades-virtuelles/'
  global.baseUrl = '/'
  global.ajaxUrl = global.baseUrl+'main.php'
  global.ajaxAdminUrl = global.baseUrl+'admin_api.php'
}

Vue.use(ElementUI, { locale })
Vue.use(VueResource)

Vue.component('v-quill', QuillComponent)
Vue.component('v-hud', HUD)
Vue.component('v-map', MapMilieu)
Vue.component('v-animated_button', animated_button)
Vue.component('v-swiper', Swiper)
Vue.component('v-point', point)
Vue.component('v-milieux', Milieux)
Vue.component('v-milieu', Milieu)
Vue.component('v-panel', Panel)
Vue.component('v-help', Help)
Vue.component('v-milieu-dialog', MilieuDialog)
Vue.component('v-point-dialog', PointDialog)
Vue.component('v-parameter-dialog', ParameterDialog)
Vue.component('v-search-dialog', SearchDialog)
Vue.component('v-download-dialog', DownloadDialog)
Vue.component('v-mediamanager', MediaManager)
Vue.directive('InfiniteScroll', InfiniteScroll)
Vue.component('draggable', draggable)

import {
  Form,
  Select,
  Button,
  Checkbox,
  Radio,
  Slider,
  Input,
  InputNumber,
  Dialog,
  Message,
} from 'element-ui'

Vue.component(Form.name, Form)
Vue.component(Select.name, Select)
Vue.component(Button.name, Button)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Radio.name, Radio)
Vue.component(Slider.name, Slider)
Vue.component(Input.name, Input)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Dialog.name, Dialog)
Vue.component(Message.name, Message)

Vue.config.productionTip = false
global.token = localStorage.getItem('id_token')
global.token_expiration_time = parseInt(localStorage.getItem('id_token_expiration'))

console.log('token', global.token)
// console.log(
//   'date',
//   global.token_expiration_time,
//   new Date().getTime()/1000,
//   global.token_expiration_time - new Date().getTime()/1000)

global.sound_started = false

if(global.token_expiration_time &&
  global.token_expiration_time > new Date().getTime()/1000){
  global.logged = true
}
else {
  global.logged = false
}


global.getMediaFormat = function(url, w, h) {
  var tmp = url.split('.')
  tmp[tmp.length-2] += '-'+w+'x'+h;
  return tmp.join('.')
}

Vue.http.interceptors.push(function (request, next) {
  // console.log('request', request)
  // console.log('request_body', request_body)
  // if(request.url.indexOf(global.ajaxAdminUrl) === -1) return

  next(function (response) {
    switch (response.status) {
      case 401:
        localStorage.removeItem('id_token')
        EventBus.$emit('session_end')
        router.replace('/admin')
        break
      default:
        if(response.body.token) {
          global.token = response.body.token
          global.token_expiration_time = parseInt(response.body.expiration)
          localStorage.setItem('id_token', global.token)
          localStorage.setItem('id_token_expiration', global.token_expiration_time)
        }
      break
    }
  })
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
