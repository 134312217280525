<template>
<div id="map-container" class="map-container vertical">
  <div class="map-wrap">
    <transition name="fade">
      <div class="map-menu" v-if="menu_visible">
        <div class="map-menu-wrap">
          <div class="map-menu-head">
            Choisissez les types de milieu à afficher et visiter
          </div>
          <div class="map-menu-body">
            <div class="cat-container"
            @click="toggle_cat(cat)"
            v-for="cat in milieu_categories.list"
            v-if="cat.deleted == 0"
            >
              <img 
              v-if="cat.media_id>0"
              class="cat-icon" :src="baseUrl+cat.icon">
              <div
              class="cat-title" 
              :class="{
                visible: cat.map_visible
              }"
              v-html="cat.title"></div>
              <div class="cat-descr" v-html="cat.descr"></div>
            </div>
          </div>
          <div class="map-menu-footer">
            <div class="map-menu-footer-icon">+</div>
            <div class="map-menu-footer-text">D'autres balades sont en cours de création, venez participer<br>baladesvirtuelles@fne-midipyrenees.fr</div>
          </div>
        </div>
      </div>
    </transition>
    <div class="theme_title_container2">
      <div class="theme_title2">
        <div class="theme_title_cat2">
        <span>
        Bienvenue en
        </span>
        </div>
        <div class="theme_title_content2">
        <span>
        Occitanie
        </span>
        </div>
      </div>
    </div>
    <img class="map" id="map" src="images/map_all_optimized3.svg">
    <div class="map-markers unselectable" id="map-markers" ref="map-wrapper">
      <div
      v-for="(milieu, milieu_index) in milieux"
      :ref="'map-point-'+milieu.id"
      v-show="milieu_categories.ref[milieu.categorie].map_visible"
      @mousedown.prevent
      @click="milieuClick(milieu)"
      @mousedown.stop.prevent="onDragStart($event, milieu)"
      class="map-marker"
      :class="{
        current: milieu_index === current_milieu_index,
        unpublished: milieu.publish == 0
      }"
      :style="{
        left: milieu.positionX+'%',
        top: milieu.positionY+'%'
      }">
        <div class="map-marker-wrap">
          <!-- <img src="/static/icons/wip.svg"> -->
          <img class="marker-icon white" src="icons/marker_white.png">
          <img class="marker-icon red" src="icons/marker_red.png">
          <div class="map-icon-wrap">
            <div class="map-icon-inner">
              <img class="marker-inside-icon" :src="baseUrl+milieu_categories.ref[milieu.categorie].icon">
            </div>
            <div class="milieu_title_container">
              <div class="milieu_title">
                <div class="milieu_title_cat">
                  <span>
                    Découvrez
                  </span>
                </div>
                <div class="milieu_title_content">
                  <span
                  v-html="milieu.title">
                  </span>
                </div>
              </div>
            </div>
          </div>

         </div>
      </div>
    </div>
    <a
    target="_blank"
    href="https://www.laregion.fr/"
    class="map-logo" style="bottom: -1%; left: 7%; height: 12%; width: 11%;">
      <img src="images/logo_region_occitanie.svg">
    </a>
    <a class="map-logo"
    target="_blank"
    href="https://www.ecologique-solidaire.gouv.fr/strategie-nationale-biodiversite#e1"
    style="bottom: -1%;left: 19.2%;height: 12%; width: 8%;">
      <img src="images/biodiver.jpg">
    </a>
    <a class="map-logo"
    target="_blank"
    href="https://www.service-civique.gouv.fr/"
    style="bottom: -1%;left: 28.7%;height: 6.6%; width: 13%;">
      <img src="images/logo service civique.jpg">
    </a>
    <div class="map-logo map-logo-fne"
    style="bottom: -1%; right: 15%; height: 18%; width: 16%;">
      <a href="http://www.fne-midipyrenees.fr/" target="_blank">
          <img src="images/FNE_Logo_Midi-Pyrenees_RVB_petit.jpg">
        </a>
    </div>
    <!-- <div class="map-button"
    @click="show_menu"
    style="background-color: #8cc63f; width: 8%;height: 9%; top: 30%;left: 4.4%;">
      <img style="width: 54%; padding-top: 18%;" src="icons/marker_white.png">
    </div> -->
    <a class="map-button" 
    href="http://www.fne-midipyrenees.fr/contactez-nous_11.php"
    target="_blank"
    style="background-color: #e1051b;width: 11%;height: 12%;bottom: 17%;right: 5%;">
      <img style="width: 60%; padding-top: 27%;" src="icons/enveloppe.svg">
    </a>
    <div class="map-button" 
    @mousedown.prevent
    @click="show_download = true"
    style="background-color: #6c9824;
    width: 8%;
    height: 9%;
    bottom: 30%;
    right: 2%;">
      <img style="width: 65%; padding-top: 18%;" src="icons/camera.svg">
    </div>
    <div class="map-button"
    @click="show_search = true"
    @mousedown.prevent
    style="background-color: #8ac543;
    width: 5.5%;
    height: 6%;
    bottom: 40.7%;
    right: 1.5%;">
      <img style="width: 90%; padding-top: 3%;" src="icons/search.svg">
    </div>
  </div>
  
  <transition name="fade">
    <v-search-dialog
    ref="search-dialog"
    @goto="goto"
    :visible.sync="show_search"
    :point_categories="point_categories"
    v-if="show_search">
    </v-search-dialog>
  </transition>

  <transition name="fade">
    <v-download-dialog
    ref="download-dialog"
    :visible.sync="show_download"
    :point_categories="point_categories"
    v-if="show_download">
    </v-download-dialog>
  </transition>

  <router-link
  v-if="$route.path !== '/admin'""
  class="goto-login-screen"
  tag="span"
  to="/admin">
    Espace privé
  </router-link>
</div>
</template>

<script>
export default {
  name: 'Map',
  props: {
    milieux: {
      type: Array
    },
    milieu_categories: {
      type: Object
    },
    point_categories: {
      type: Object
    },
    current_milieu_index: {
      type: Number
    },
    drag_mode: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      baseUrl: global.baseUrl,
      menu_visible: true,
      show_search: false,
      show_download: false,
    }
  },
  methods: {
    goto: function(data) {
      this.$emit('goto', data)
    },
    milieuClick: function(milieu){
      if(this.drag_mode) return
      this.$emit('milieuClick', milieu)
    },
    toggle_cat: function(cat){
      cat.map_visible = !cat.map_visible
    },
    show_menu: function(milieu){
      this.menu_visible = true
    },
    hide_menu: function(milieu){
      this.menu_visible = false
    },
    onDragStart: function(e, milieu){
      if(!this.drag_mode) return

      var map_wrapper = this.$refs['map-wrapper']
      // var map_background = this.$refs['map-background']
      var point_el = this.$refs['map-point-'+milieu.id][0]

      var marker_offset = point_el.getBoundingClientRect(),
        container_offset = map_wrapper.getBoundingClientRect(),
        decal = {
          left: (e.pageX-marker_offset.left),
          top: (e.pageY-marker_offset.top)
        }

      var mousemove_listener = function(ev){
        milieu.positionX = (((ev.pageX-decal.left-container_offset.left)/container_offset.width))*100
        milieu.positionY = (((ev.pageY-decal.top-container_offset.top)/container_offset.height))*100
      }

      map_wrapper
      .addEventListener('mouseup', function(ev){
        map_wrapper
        .removeEventListener('mousemove', mousemove_listener)
      })

      map_wrapper
      .addEventListener('mousemove', mousemove_listener)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/css">

.map-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 4000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px;
  padding-bottom: 50px;
  text-align: center;
}

.map-wrap {
  margin: auto;
  position: relative;
  display: inline-block;
}

.map {
  display: block;
  margin: auto;
  height: 100%;
}

.horizontal2 .map-wrap,
.horizontal2 .map {
  width: auto;
  height: 100%;
}

.map-wrap,
.map {
  width: 100%;
  height: auto;
}

.map-button {
  position: absolute;
  border: 2px solid #fff;
  border-radius: 100px;
  box-shadow: 2px 5px 10px rgba(0,0,0,0.7);
  cursor: pointer;
}

.map-button > i {
  color: #fff;
  font-size: 40px;
}

.map-logo-fne {
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}

.map-logo-fne a {
  border-radius: 50%;
  padding: 2% 0% 13% 4%;
  padding: 5% 0% 18% 12%;
}

.map-logo {
  position: absolute;
  box-shadow: 2px 5px 10px rgba(0,0,0,0.7);
  display: inline-block;
}

.map-logo a,
.map-logo img {
  height: 100%;
  /*width: 100%;*/
  width: auto;
  position: relative;
  display: block;
}


.map-markers {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.map-marker {
  position: absolute;
  width: 5%;
}

.map-marker:hover {
  z-index: 6000;
}

.map-marker-wrap {
    cursor: pointer;
    width: 100%;
}

.map-icon-wrap {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
}

.map-icon-inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.marker-inside-icon {
  width: 100%;
  vertical-align: top;
  border: 0.5vh solid #fff;
  border-radius: 100px;
}

.map-marker .marker-icon {
    width: 100%;
}

.map-marker .marker-icon.red {
  display: none;
}

.map-marker:hover .marker-icon.red {
  display: block;
}

.map-marker:hover .marker-inside-icon {
  border-color: #990000;
}

.map-marker:hover .marker-icon.white {
  display: none;
}

.map-menu {
  width: 15vw;
  min-width: 280px;
  position: absolute;
  text-align: left;
  background-color: #fff;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.map-menu-wrap {
  width: 100%;
  position: relative;
}

.map-menu-head {
  background-color: #8cc63f;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 50px;
  padding-bottom: 8px;
  line-height: 22px;
}

.map-menu-head .close-btn {
  top: -16px;
  right: 12px;
  color: #fff;
  background-color: #af1624;
  font-size: 26px;
  width: 26px;
  height: 26px;
  line-height: 26px;
}

.map-menu-footer,
.map-menu-body {
  padding: 10px;
}

.map-menu .cat-icon {
  float: left;
  width: 40px;
  margin-right: 10px;
}

.map-menu .cat-title {
  color: #3f8f22;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
}

.map-menu .cat-title.visible:after {
  content: '\2714';
  float: right;
  padding-right: 5px;
}

.map-menu .cat-container {
  clear: left;
  cursor: pointer;
}

.cat-descr {
  line-height: 18px;
}
.map-menu .cat-title,
.map-menu .cat-descr {
  padding-left: 50px;
}

.map-menu-footer {
  color: #8cc63f;
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid #8cc63f;
}

.map-menu-footer-icon {
  font-size: 60px;
  font-weight: normal;
  float: left;
  margin-right: 10px;
  line-height: 60px;
  height: 60px;
}

.map-menu-footer-text {
  padding-left: 44px;
  line-height: 18px;
}

.milieu_title_container {
  position: relative;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: none;
  transform: translate(-82px, 28%);
}

.map-marker:hover .milieu_title_container {
  display: block;
}

.milieu_title {
  display: inline-block;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
}

.milieu_title_cat {
  text-align: left;
  margin-right: 20px;
}

.milieu_title_content {
  text-align: right;
  margin-left: 20px;
  font-size: 18px;
}

.milieu_title_content > span,
.milieu_title_cat > span {
  display: inline-block;
  padding: 0px 10px;
}

.milieu_title_cat > span {
  background-color: #af1624;
}

.milieu_title_content > span {
  background-color: #af1624;
  text-align: left;
}

.map-marker.current {
  z-index: 99;
}

.map-marker.current:hover:before {
  display: none;
}

.map-marker.current:before {
  content: "Vous êtes ici";
  position: absolute;
  z-index: 100;
  left: 0;
  top: 40%;
  transform: translate(-100%, -50%);
  display: inline-block;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  padding: 0 5px;
  white-space: nowrap;
  background-color: #af1624;
}

.app-title-container {
  position: absolute;
  color: #fff;
  font-size: 42px;
  line-height: 42px;
  font-weight: bold;
  text-align: left;
  top: -25px;
  left: 0;
}

.app-title1 {
  display: inline-block;
  background-color: #e1051b;
  padding-left: 12%;
  margin-bottom: 10px;
  padding-right: 4%;
}

.app-title2 {
  display: inline-block;
  background-color: #8ac543;
  margin-left: 12%;
  padding-left: 5px;
  padding-right: 4%;
}

.map-container .theme_title_container2 {
  position: absolute;
  margin: auto;
  margin-top: 0;
  top: -32px;
}

.map-container .theme_title_cat2 {
  margin-right: 5vh;
  margin-bottom: -1px;
}

.map-container .theme_title_cat2 > span {
  line-height: 4vh;
  font-size: 4vh;
  padding: 0 2vh;
}

.map-container .theme_title_content2 {
  margin-left: 5vh;
}

.map-container .theme_title_content2 > span {
  line-height: 6vh;
  font-size: 6vh;
  padding: 0 3vh;
}
/*
.map-marker.unpublished {
  opacity: 0.5;
}*/

.map-marker.unpublished:after {
  /*content: "\e911";
  font-family: 'icomoon';
  font-size: 40px;*/
  content: '';
  /*background-image: url("/static/icons/wip.svg");*/
  background-image: url("//icons/wip.svg");
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: -3px;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.7;
}

</style>
