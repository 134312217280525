'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

var _cropperjs = require('cropperjs');

var _cropperjs2 = _interopRequireDefault(_cropperjs);

require('cropperjs/dist/cropper.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var CropperComponent = _vue2.default.extend({
    render: function render(h) {
        return h('div', { style: this.containerStyle }, [h('img', {
            ref: 'img',
            attrs: {
                src: this.src,
                alt: this.alt || 'image',
                style: 'max-width: 100%'
            },
            style: this.imgStyle
        })]);
    },

    props: {
        'containerStyle': Object,
        'src': {
            type: String,
            default: ''
        },
        'alt': String,
        'imgStyle': Object,

        'viewMode': Number,
        'dragMode': String,
        'aspectRatio': Number,
        'data': Object,
        'preview': String,
        'responsive': {
            type: Boolean,
            default: true
        },
        'restore': {
            type: Boolean,
            default: true
        },
        'checkCrossOrigin': {
            type: Boolean,
            default: true
        },
        'checkOrientation': {
            type: Boolean,
            default: true
        },
        'modal': {
            type: Boolean,
            default: true
        },
        'guides': {
            type: Boolean,
            default: true
        },
        'center': {
            type: Boolean,
            default: true
        },
        'highlight': {
            type: Boolean,
            default: true
        },
        'background': {
            type: Boolean,
            default: true
        },
        'autoCrop': {
            type: Boolean,
            default: true
        },
        'autoCropArea': Number,
        'movable': {
            type: Boolean,
            default: true
        },
        'rotatable': {
            type: Boolean,
            default: true
        },
        'scalable': {
            type: Boolean,
            default: true
        },
        'zoomable': {
            type: Boolean,
            default: true
        },
        'zoomOnTouch': {
            type: Boolean,
            default: true
        },
        'zoomOnWheel': {
            type: Boolean,
            default: true
        },
        'wheelZoomRatio': Number,
        'cropBoxMovable': {
            type: Boolean,
            default: true
        },
        'cropBoxResizable': {
            type: Boolean,
            default: true
        },
        'toggleDragModeOnDblclick': {
            type: Boolean,
            default: true
        },

        'minCanvasWidth': Number,
        'minCanvasHeight': Number,
        'minCropBoxWidth': Number,
        'minCropBoxHeight': Number,
        'minContainerWidth': Number,
        'minContainerHeight': Number,

        'ready': Function,
        'cropstart': Function,
        'cropmove': Function,
        'cropend': Function,
        'crop': Function,
        'zoom': Function
    },
    watch: {
        aspectRatio: function(val){
            

        }
    },
    mounted: function mounted() {
        var _$options$props = this.$options.props,
            containerStyle = _$options$props.containerStyle,
            src = _$options$props.src,
            alt = _$options$props.alt,
            imgStyle = _$options$props.imgStyle,
            data = _objectWithoutProperties(_$options$props, ['containerStyle', 'src', 'alt', 'imgStyle']);

        var props = {};

        for (var key in data) {
            if (this[key] !== undefined) {
                props[key] = this[key];
            }
        }

        this.cropper = new _cropperjs2.default(this.$refs.img, props);
    },

    methods: {
        reset: function reset() {
            return this.cropper.reset();
        },
        clear: function clear() {
            return this.cropper.clear();
        },
        replace: function replace(url) {
            var onlyColorChanged = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            return this.cropper.replace(url, onlyColorChanged);
        },
        enable: function enable() {
            return this.cropper.enable();
        },
        disable: function disable() {
            return this.cropper.disable();
        },
        destroy: function destroy() {
            return this.cropper.destroy();
        },
        move: function move(offsetX, offsetY) {
            return this.cropper.move(offsetX, offsetY);
        },
        moveTo: function moveTo(x) {
            var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : x;

            return this.cropper.moveTo(x, y);
        },
        relativeZoom: function relativeZoom(ratio, _originalEvent) {
            return this.cropper.zoom(ratio, _originalEvent);
        },
        zoomTo: function zoomTo(ratio, _originalEvent) {
            return this.cropper.zoomTo(ratio, _originalEvent);
        },
        rotate: function rotate(degree) {
            return this.cropper.rotate(degree);
        },
        rotateTo: function rotateTo(degree) {
            return this.cropper.rotateTo(degree);
        },
        scaleX: function scaleX(_scaleX) {
            return this.cropper.scaleX(_scaleX);
        },
        scaleY: function scaleY(_scaleY) {
            return this.cropper.scaleY(_scaleY);
        },
        scale: function scale(scaleX) {
            var scaleY = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : scaleX;

            return this.cropper.scale(scaleX, scaleY);
        },
        getData: function getData() {
            var rounded = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            return this.cropper.getData(rounded);
        },
        setData: function setData(data) {
            return this.cropper.setData(data);
        },
        getContainerData: function getContainerData() {
            return this.cropper.getContainerData();
        },
        getImageData: function getImageData() {
            return this.cropper.getImageData();
        },
        getCanvasData: function getCanvasData() {
            return this.cropper.getCanvasData();
        },
        setCanvasData: function setCanvasData(data) {
            return this.cropper.setCanvasData(data);
        },
        getCropBoxData: function getCropBoxData() {
            return this.cropper.getCropBoxData();
        },
        setCropBoxData: function setCropBoxData(data) {
            return this.cropper.setCropBoxData(data);
        },
        getCroppedCanvas: function getCroppedCanvas() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            return this.cropper.getCroppedCanvas(options);
        },
        setAspectRatio: function setAspectRatio(aspectRatio) {
            return this.cropper.setAspectRatio(aspectRatio);
        },
        setDragMode: function setDragMode(mode) {
            return this.cropper.setDragMode(mode);
        }
    }
});

var VueCropper = _vue2.default.component('vue-cropper', CropperComponent);

exports.default = VueCropper;