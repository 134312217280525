<template>
  <div class="milieu-container cover">
    <div
    ref="milieu-inner"
    @mousedown.prevent
    v-loading="milieu.show_markers && !milieu.loaded"
    element-loading-background="rgba(0, 0, 0, 0.25)"
    class="milieu-inner"
    :class="{
      blur: milieu.blur
    }">
        <div 
        class="milieu-background blur">
          <img :src="baseUrl + milieu.photo_path_sized">
        </div>
        <div class="milieu-background clear"
        ref="milieu-background">
          <img
          @load="backgroundLoad"
          :src="baseUrl + milieu.photo_path_sized">
            <transition name="fade">
              <div class="interest-point-container"
              v-if="milieu.show_markers">
                <div
                v-if="milieu.loaded && point.deleted == 0"
                v-for="point in milieu.points"
                :ref="'milieu-point-'+point.id"
                @click.stop="pointClick(point)"
                @mousedown.stop.prevent="onDragStart($event, point)"
                class="interest-point"
                :data-point_id="point.id"
                :style="{
                  'background-image': 'url(\''+((point.media.length>0)?
                    point.media[0].thumb_url
                  :'static/images/defaut.png')+'\')',
                  'top': 'calc('+point.positionY+'% - 55px)',
                  'left': 'calc('+point.positionX+'% - 55px)',
                  'border-color': point_categories.ref[point.categorie].color
                }">
                  <div class="point_title_container">
                    <div class="point_title">
                      <div class="point_title_cat">
                        <span
                        v-html="point_categories.ref[point.categorie].title"
                        :style="{
                          'background-color': point_categories.ref[point.categorie].color
                        }">
                        </span>
                        <div class="point_title_content">
                          <span
                          v-html="point.title">
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
        </div>
    </div>

  </div>
</template>
<script>
import { EventBus } from '@/components/event_bus.js'

export default {
  props: {
    milieu: {
      type: Object
    },
    blur: {
      type: Boolean,
      default: false
    },
    logged: {
      type: Boolean,
      default: false
    },
    drag_mode: {
      type: Boolean,
      default: false
    },
    point_categories: {
      type: Object,
      default: false
    }
  },
  data () {
    return {
      baseUrl: global.baseUrl,
      point_opened: false,
      seletected_point: {},
      // drag_mode: false
    }
  },
  activated () {
    // this.getMilieuMap()
  },
  mounted () {
    var vm = this

  },
  methods: {
    backgroundLoad: function(){
      this.milieu.background_loaded = true
      this.$emit("backgroundLoad", this.milieu)
    },
    pointClick: function(point){
      if(this.drag_mode) return
      this.$emit("pointOpen", point)
    },
    onDragStart: function(e, point){
      // e.preventDefault()
      if(!this.drag_mode) return

      var milieu_wrapper = this.$refs['milieu-inner']
      var milieu_background = this.$refs['milieu-background']
      var point_el = this.$refs['milieu-point-'+point.id][0]

      // console.log('onDragStart', milieu_background, point_el)

      var marker_offset = point_el.getBoundingClientRect(),
        container_offset = milieu_background.getBoundingClientRect(),
        decal = {
          left: (e.pageX-marker_offset.left)-(marker_offset.width/2)+container_offset.left,
          top: e.pageY-marker_offset.top-(marker_offset.height/2)+container_offset.top
        }

      var mousemove_listener = function(ev){
        // console.log('mousemove_listener', point.positionX, point.positionY)
        point.positionX = (((ev.pageX-decal.left)/container_offset.width))*100
        point.positionY = (((ev.pageY-decal.top)/container_offset.height))*100
      }

      milieu_wrapper
      .addEventListener('mouseup', function(ev){
        milieu_wrapper
        .removeEventListener('mousemove', mousemove_listener)
      })

      milieu_wrapper
      .addEventListener('mousemove', mousemove_listener)
    },
    save: function(){
      var post_data
      var action

      this.$http.post(
        global.baseUrl+"main.php?action=set_milieu", {
          milieu_id: this.milieu_id,
          title: this.form.title,
          descr: this.form.descr,
        }, 
        {
          emulateJSON: false
        }).then(function(data){
          var resp = data.body
          var new_milieu_id = parseInt(data.body.milieu_id)

          if(this.milieu_id !== new_milieu_id){
            this.$router.replace('/milieu/'+new_milieu_id)
          }
        }, function(data){
      });
    }
  }
}
</script>
<style type="text/css">
.milieu-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.milieu-inner {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.milieu-wrapper {

}

.milieu-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
}

.milieu-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.3s ease;
  background-color: #000;
  overflow: hidden;
}

.milieu-background img {
  display: block;
  margin: auto;
}

.milieu-background,
.milieu-background img {
  width: 100%;
  height: auto;
}

.horizontal .milieu-background,
.horizontal .milieu-background img {
  height: 100%;
  width: auto;
}

.cover .milieu-background,
.cover .milieu-background img {
  height: 100%;
  width: auto;
}

.horizontal .cover .milieu-background,
.horizontal .cover .milieu-background img {
  width: 100%;
  height: auto;
}

.milieu-background0.clear {
  opacity: 0.9;
}

.milieu-background.blur img{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='5'); /* IE lte 9 */
  z-index: 10;
}

.milieu-background.clear {
  transition: opacity 0.5s ease-in-out;
  z-index: 100;
  /*will-change: opacity;*/
}

.milieu-background.blur {
  z-index: 99;
}

.blur .milieu-background.clear {
  opacity: 0;
}

/*** Points ***/

.interest-point {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 310px;
  border: 10px solid #a1161c;
  background-color: #fff;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 2px #0008;
  cursor: pointer;
}

.interest-point:hover {
  z-index: 100;
}

.interest-point:after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  bottom: -3px;
  right: -3px;
  border-radius: 310px;
  border: 3px solid #fff;
}

.point_title_container {
  text-align: center;
  position: absolute;
  z-index: 100;
  display: none;
  right: 70%;
  bottom: -5px;
}

.interest-point:hover .point_title_container {
  display: block;
}

.point_title {
  display: inline-block;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
}

.point_title_cat {
  position: relative;
  text-align: left;
  margin-right: 20px;
}

.point_title_content {
  text-align: right;
  margin-left: 20px;
  font-size: 19px;
  position: absolute;
  top: 21px;
  left: 0;
}

.point_title_content > span,
.point_title_cat > span {
  display: inline-block;
  padding: 0px 10px;
}

.point_title_cat > span {
  background-color: #71a024;
}

.point_title_content > span {
  background-color: #af1624;
  text-align: left;
}

</style>