<template>
  <div 
  ref="milieu-panel"
  class="milieu-panel"
  :class="{
    'panel-hidden': hidden,
    'panel-collapse': collapsed
  }">
    <div class="milieu-panel-inner">
      <div class="milieu-panel-top">
        <transition name="fade">
          <div 
          v-if="collapsed"
          @click="uncollapse"
          class="milieu-panel-open">
            <i class="icon-files_green"></i>
            <span>
              Description & Enjeux
            </span>
          </div>
        </transition>
        <transition name="fade">
          <div 
          v-if="!collapsed"
          @click="collapse"
          class="milieu-panel-close">
          </div>
        </transition>
      </div>
    </div>
    <div v-if="milieu"
    class="milieu-panel-main">
      <div class="milieu-panel-main-container">
        <h1><span v-html="milieu.title"></span>
          <div class="edit-button admin-button"
          v-if="logged"
          @click="edit">
            <i class="icon-pencil"></i>
          </div>
        </h1>
        <div class="tabs">
          <div class="description-tab tab"
          @mousedown.prevent
          @click="tab='description'"
          :class="{
            active: tab==='description'
          }">
              <i class="icon-files_green"></i>
            <span>
            Description
            </span>
          </div>
          <div class="enjeux-tab tab"
          @mousedown.prevent
          @click="tab='enjeux'"
          :class="{
            active: tab==='enjeux'
          }">
            <i class="icon-warning"></i>
            <span>
            Enjeux
            </span>
          </div>
        </div>
        <div class="tabs-content" style="flex: 1; min-height: 200px;">
          <div
          class="tab-content"
          v-if="tab==='description'"
          v-html="milieu.description">
          </div>
          <div
          class="tab-content"
          v-if="tab==='enjeux'"
          v-html="milieu.enjeux">
          </div>
        </div>
        <div class="licence_block">
          <i v-if="milieu.credits_photo!=''">
            Crédits photo:
            <span v-html="milieu.credits_photo"></span>,
          </i>
          <i v-if="milieu.licence_photo!=0">
            licence:
            <a target="_blank"
            :href="(licences.ref[milieu.licence_photo])?
            licences.ref[milieu.licence_photo].link:''"
            v-html="(licences.ref[milieu.licence_photo])?
            licences.ref[milieu.licence_photo].title:''"></a>
          </i>
        </div>
        <div class="licence_block">
          <i
          v-if="milieu.credits_sound!=''"
          >Crédits son: <span v-html="milieu.credits_sound"></span>, </i>
          <i v-if="milieu.licence_sound!=0">
          licence:
            <a target="_blank"
            :href="(licences.ref[milieu.licence_sound])?
            licences.ref[milieu.licence_sound].link:''"
            v-html="(licences.ref[milieu.licence_sound])?
            licences.ref[milieu.licence_sound].title:''"></a>
          </i>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Panel',
  props: {
    hidden: {
      type: Boolean,
      default: false
    },
    milieu: {
      type: Object
    },
    licences: {
      type: Object
    },
    logged: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      collapsed: false,
      tab: 'description'
    }
  },
  mounted () {
    var vm = this
    var milieu_panel = this.$refs["milieu-panel"]

    milieu_panel.addEventListener(window.transitionEnd, function(){
      vm.tab = 'description'
      vm.$emit("transitionend")
    }, false);
  },
  methods: {
    collapse: function() {
      this.collapsed = true
    },
    uncollapse: function() {
      this.collapsed = false
    },
    edit: function() {
      this.$emit('edit')
    }
  }
}
</script>
<style type="text/css">

/*** Panel ***/
.milieu-panel {
  position: fixed;
  left: 0;
  top: 0;
  width: 23vw;
  min-width: 320px;
  height: 100vh;
  z-index: 2000;
  transition: transform 0.6s ease-in-out;
}

.milieu-panel-main {
  transition: transform 0.6s ease-in-out;
  will-change: transform;
}

.panel-hidden {
  transform: translateX(-102%);
}

.panel-collapse .milieu-panel-main {
  transform: translateX(-100%);
}

.milieu-panel-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.milieu-panel-top {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e1051b;
  height: 55px;
  width: 100%;
  transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
  overflow: hidden;
  border-bottom-right-radius: 1px;
}

.milieu-panel-close {
  color: #FFF;
  font-size: 28px;
  width: 50px;
  height: 55px;
  line-height: 50px;
  float: right;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.6s ease;
  position: relative;
}

.milieu-panel-close0:after {
  content: "\3008\3008";
  content: "\25C0";
  content: "\276E";
  color: #FFF;
  font-size: 28px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.milieu-panel-close:after {
  content: "\3008\3008";
  content: "\25C0";
  content: "\276E";
  color: #FFF;
  font-size: 28px;
  display: inline-block;
  width: 50px;
  height: 55px;
}

.milieu-panel-open {
  color: #FFF;
  float: left;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.6s ease;
  padding: 15px 5px 10px 5px;
  background-color: #e1051b;
}

.milieu-panel-open > i {
  line-height: 80px;
  font-size: 80px;
}

.panel-collapse .milieu-panel-top {
  width: 120px;
  height: 143px;
}

.milieu-panel-open > span {
  display: block;
  font-weight: 700;
  width: 110px;
  font-size: 16px;
  line-height: 16px;
  padding-top: 5px;
}


.milieu-panel-main {
  position: absolute;
  left: 0;
  right: 50px;
  top: 13px;
  bottom: 0;
  background-color: #fff;
  border-bottom: 3px solid #e1051b;
  padding-bottom: 260px;
}

.milieu-panel-main-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.milieu-panel-main h1 {
  font-size: 2.2rem;
  line-height: 2.8rem;
  padding: 0 30px;
  margin-top: 10px;
  margin-bottom: 16px;
}

.milieu-panel-main h1 span {
  word-wrap: break-word;
}

.milieu-panel-main .licence_block {
  padding: 3px 15px;
  font-size: 14px;
}

.description-tab {
  color: #003800;
}

.enjeux-tab {
  color: #ba3f00;
}

/*** Tabs ***/

.tabs {
    padding: 0 10px;
    z-index: 100;
}

.tab {
  display: inline-block;
  border: 1px solid #888;
  background-color: #fff;
  box-shadow: 0 -8px 7px -9px rgba(0, 0, 0, 0.2) inset, -2px -2px 5px -2px rgba(0, 0, 0, 0.1);
  margin-right: 3px;
  padding: 8px 16px;
  border-radius: 3px 3px 0 0;
  white-space: nowrap;
  margin-bottom: -1px;
  z-index: 90;
  font-weight: bold;
  font-size: 1rem;
  vertical-align: middle;
  height: 42px;
  cursor: pointer;
  background-color: #eee;
}

.tab > span {
  display: inline-block;
  height: 100%;
  line-height: 26px;
  vertical-align: top;
}

.tab > i {
  font-size: 26px;
  line-height: 26px;
  margin-right: 3px;
}

.tab.active {
  border-bottom: 1px solid #fff;
  background-color: #fff;
  box-shadow: -2px -2px 5px -2px rgba(0, 0, 0, 0.1);
}

.tabs-content {
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-left: 10px;
  overflow: visible;
  margin-bottom: 10px;
}

.tab-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 10px 20px;
}


@media(max-width: 1466px) {
  .milieu-panel .tab {
    width: 100%;
  }
}

</style>
