<template>
  <div 
  class='point-wrapper'
  ref='point-wrapper'
  :class="{
    isMoving: isMoving
  }"
  :style="{
    transform: 'translateX('+(decal_left + decal_unit)+')'
  }">
    <div
    class='container point-container'>
      <v-swiper 
      ref="swiper"
      @onStart='onSwipeStart'
      @onEnd='onSwipeEnd'
      @onMove='onSwipe'
      class="post-card">
        <div class="post-card-wrap">

          <div
          v-if="logged"
          class="point-toolbar">
            <div class="save-button admin-button"
            style="margin: 5px;"
            title="Sauver le positionnement"
            @click="editPoint">
              <i class="icon-pencil"></i>
            </div>
          </div>

          <div class="card-images-wrap"
          @mouseover="stopImageRotation"
          @mouseout="startImageRotation">
            <transition-group name="fade">
              <div class="card-image-wrap"
              v-for="(image, image_index) in point.media"
              :id="'media-'+image.media_id"
              :class='{
                transition: image_transition,
                cover: !!image.original
              }'
              :key="image.media_id"
              v-if="image_index === currentImageIndex"
              :style="{
                'background-image': 'url(\'' + ((image.original == 0)?image.point_url:baseUrl+image.file_path) + '\')',
              }">
                <div class="card-img-legend"
                v-if="image.legend !== ''"
                v-html="'Photographe: '+image.legend">
                </div>
              </div>
              <div class="card-image-wrap default"
              key="media-0"
              v-if="point.media.length == 0"
              style="background-size: contain;
              background-image: url('static/images/defaut.png');">
              </div>
            </transition-group>
            <div class="card-button-container"
            v-if="point.media.length > 1">
              <div
              :key="image.media_id"
              v-for="(image, button_index) in point.media"
              @click="showImage(button_index)"
              @mousedown.stop
              class="card-img-button"
              :class="{
                active: button_index === currentImageIndex
              }"></div>
            </div>
          </div>
       
          <div class="card-content-wrap">
            <div class="btn close-btn"
            @mousedown.stop
            @click="close">
              <i class="icon-cross"></i>
            </div>
            <div class="theme_title_container">
              <div class="theme_title">
                <div class="theme_title_cat">
                  <span
                  @mousedown.stop
                  v-html="((point_categories.ref[point.categorie])?
                    point_categories.ref[point.categorie].title:'')"
                  :style="{
                    'background-color': ((point_categories.ref[point.categorie])?
                    point_categories.ref[point.categorie].color:'#fff')
                  }">
                  </span>
                </div>
                <div class="theme_title_content">
                  <span
                  @mousedown.stop
                  v-html="point.title">
                  </span>
                  <!-- <input v-focus> -->
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="rando-descr">
                <div class="rando-descr-inner"
                @mousedown.stop
                v-html="point.descr">
                </div>
              </div>
              <div v-if="point.link_txt != ''">
                <div style="color: #72a125; margin-top: 10px;">
                  A voir aussi:
                </div>
                <a 
                target="_blank"
                :href="point.link_url"
                v-html="point.link_txt">
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card-actions align-center">
          <el-button 
          @click="close"
          type="primary" icon="el-icon-map">
            Retour à la balade
          </el-button>
        </div> -->

      </v-swiper>
    </div>
  </div>
</template>
<script>
var rAF = window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function (callback) { window.setTimeout(callback, 1000 / 60) }

export default {
  // directives: {infiniteScroll},
  name: 'interestPoint',
  props: {
    point: {
      type: Object
    },
    opened: {
      required: true
    },
    logged: {
      type: Boolean,
      default: false
    },
    point_categories: {
      type: Object,
      default: false
    }
  },

  data () {
    return {
      baseUrl: global.baseUrl,
      decal_left: 0,
      decal_unit: 'vw',
      isMoving: false,
      currentImageIndex: 0,
      timer: null,
      is_opened: false,
      image_transition: false
    }
  },
  mounted () {
    var vm = this
    var swiper = this.$refs.swiper
    // this.startImageRotation()


    var point_wrapper = this.$refs['point-wrapper']

    point_wrapper.addEventListener(window.transitionEnd, function(e){
      if(e.target !== point_wrapper) return
      // console.log("point_wrapper transitionend")
      vm.$emit("transitionend")
      vm.is_opened = vm.opened 
      if(vm.is_opened){
        vm.startImageRotation()
        vm.image_transition = true
        vm.currentImageIndex = 0
      }
      else {
        vm.currentImageIndex = 0
      }
    }, false);
  },
  watch: {
    opened: function (opened) {
      if(opened) {
        this.decal_left = 0
        this.decal_unit = 'vw'
        this.currentImageIndex = 0
        // this.is_opened = false
        this.isMoving = false
      }
      else {
        this.decal_left = 100
        this.decal_unit = 'vw'
        this.stopImageRotation()
        this.image_transition = false
        // this.is_opened = true
        this.isMoving = false
      }
    }
  },
  methods: {
    editPoint: function() {
      this.$emit('edit', this.point)
    },
    startImageRotation: function() {
      if(!this.timer && this.opened && this.point.media.length > 1) {
        this.timer = setInterval(this.nextImage, 3000)
        // console.log("timer new", this.timer)
      }
    },
    stopImageRotation: function() {
      if(this.timer) {
        // console.log("timer cleared", this.timer)
        clearInterval(this.timer)
        this.timer = null
      }
    },
    resetImageRotation: function() {
      this.stopImageRotation()
      this.startImageRotation()
    },
    nextImage: function() {
      this.currentImageIndex += 1
      if(this.currentImageIndex === this.point.media.length) {
        this.currentImageIndex = 0
      }
    },
    showImage: function(index) {
      this.currentImageIndex = index
      // this.resetImageRotation()
    },

    onSwipeStart: function (e, position) {
      // console.log('onSwipeStart', position)
      this.initialPosition = position
      this.currentPosition = position
      // this.previousPosition = position
      this.isMoving = true
      this.previousPosition = {
        top: this.initialPosition.top,
        left: this.initialPosition.left
      }

      this.anim()
    },
    onSwipeEnd: function (e, position) {
      // console.log(e, position)

      this.isMoving = false
      var distance_left = this.initialPosition.left - this.currentPosition.left

      if (this.decal_left > window.innerWidth / 5) {
        this.close()
      }
      else {
        this.open()
      }
    },
    onSwipe: function (e, position) {
      // console.log('onSwipe', position)
      this.currentPosition = position

    },
    anim: function () {
      if (this.isMoving) {
        if (this.previousPosition.left !== this.currentPosition.left) {
          var decal_left = this.currentPosition.left - this.initialPosition.left

          if(decal_left > 0) {
            this.decal_left = decal_left
            this.decal_unit = 'px'
          }
          
          this.previousPosition.left = this.currentPosition.left
        }

        rAF(this.anim)
      }
    },
    open: function () {
      var vm = this

      this.currentImageIndex = 0
    // requestAnimationFrame(function(){
      // this.isMoving = false
      this.$nextTick(function(){
      // requestAnimationFrame(function(){
        vm.decal_left = 0
        vm.decal_unit = 'px'
        vm.$emit('update:opened', true)
      })
      // })
    },
    close: function () {
      // console.log('closed')
      this.$nextTick(function(){
        this.decal_left = 100
        this.decal_unit = 'vw'
        this.$emit('update:opened', false)
        this.$emit('close')
      })
    }
  }
}

</script>
<style type="text/css">
.point-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 145;
  height: 100%;
  width: 100vw;
  z-index: 5000;
  will-change: transform;
}
/*
.point-wrapper {
  position: absolute;
  top: 0;
}
*/
.point-container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.post-card {
  background-color: #fff;
  z-index: 25;
  width: 100vw;
  overflow: hidden;
  position: relative;
}


.card-img {
  height: auto;
  /*padding-top: 70%;*/
  width: 100%;
}

/*
.card-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
*/


.rando-descr {
  margin-top: 60px;
}

.theme_title {
  display: inline-block;
  font-weight: bold;
  color: #fff;
  font-size: 22px;
  margin-top: -32px;
  white-space: nowrap;
}

.theme_title_cat {
  text-align: left;
  margin-right: 30px;
}

.theme_title_content {
  text-align: right;
  margin-left: 30px;
}

.theme_title_content > span,
.theme_title_cat > span {
  display: inline-block;
  padding: 5px 10px;
}

.theme_title_cat > span {
  background-color: #71a024;
}

.theme_title_content > span {
  background-color: #af1624;
  text-align: left;
}

.card-content-wrap {
  width: 100%;
  position: relative;
  padding: 30px 20px 10px;
}

.card-images-wrap > span {
  display: block;
  width: 100%;
  height: 100%;
}

.card-image-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.card-image-wrap.cover {
  width: 100%;
  background-size: contain;
}

@media(max-width: 966px) {
  .post-card {
    overflow: auto;
  }
  .post-card-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .card-actions {
    position: absolute;
    left: 0;
    bottom: 45px;
    right: 0;
  }

  .point-wrapper .post-card {
    height: 100vh;
    width: 100vw;
  }

  .card-images-wrap {
    float: left;
    width: 100%;
    height: 50vh;
    position: relative;
    user-select: none;
  }

  .card-img {
    height: 100%;
    width: auto;
  }

  .card-content-wrap {
    float: left;
    width: 100%;
    max-height: 50vh;
  }

  .theme_title_container {
    position: relative;
    text-align: center;
    margin: auto;
    margin-top: 20px;
  }

  .rando-descr {
      margin-top: 20px;
  }
}

@media(min-width: 967px) {
/*@media(min-width: 1200px) {*/
  .post-card-wrap {
    height: 100%;
    width: 100%;
  }
/*
  .point-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
  }*/

  .point-wrapper .post-card {
    /*width: 1110px;*/
    /*height: 393px;*/
    /*width: 1062px;*/
    /*height: 354px;*/
    width: 1326px;
    height: 442px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .card-images-wrap {
    float: left;
    width: 50%;
    height: 100%;
    /*overflow: hidden;*/
    position: relative;
    user-select: none;
  }

  .card-content-wrap {
    /*flex-grow: 1;*/
    float: left;
    max-width: 50%;
    height: 100%;
  }

  .post-card {
    overflow: visible;
    box-shadow: 0 0 15px #0008;
  }

  .card-img {
    height: 100%;
    width: 100%;
    display: block;
    /*margin: auto;*/
    object-fit: cover;
    position: absolute;
  }

  .theme_title_container {
    position: relative;
    text-align: center;
    position: absolute;
    left: 0;
  }

  .theme_title {
    font-size: 28px;
    position: absolute;
    margin-top: 0;
    top: -120px;
    left: 0px;
    transform: translateX(-50%);
    padding: 0;
    background-color: transparent;
  }
  
  .card-content {
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .rando-descr {
    margin-top: 0;
    flex: 1;
    overflow: auto;
    min-height: 200px;
  }
}

.card-button-container {
  position: absolute;
  bottom: -22px;
  width: 100%;
  text-align: center;
}

.card-img-button {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #af1624;
  margin: 5px;
  cursor: pointer;
  border-radius: 1px;
  transition: background-color .3s ease-in-out;
}

.card-img-button.active {
  background-color: #739E30;
}

.card-image-wrap {
  transition: opacity .1s ease-in-out;
}

.card-image-wrap.transition {
  transition: opacity .6s ease-in-out;
}

.card-img-legend {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 5px 10px;
  text-shadow: 0 0 8px #000,0 0 4px #000,0 0 2px #000,0 0 1px #000;
  background-color: rgba(0,0,0,.05);
  font-size: 14px;
}

.point-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
}

</style>

