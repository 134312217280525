<template>
<transition name="fade2">
  <el-dialog
  title="Édition du point"
  class="point-dialog"
  @close="onClose"
  :visible="true"
  :before-close="onClose"
  :append-to-body="false"
  width="80%">
    <el-form ref="form" :model="point" label-width="80px">
      <el-row :gutter="26">
        <el-col :md="6">
          <el-form-item label="Titre">
            <el-input v-model="point.title"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item
          label="Catégorie">
            <el-select v-model="point.categorie"
            filterable
            @change="setCat"
            placeholder="Sélectionnez le type">
              <el-option
              v-for="point_categorie in point_categories.list"
              v-if="point_categorie.deleted === 0"
              :key="point_categorie.id"
              :label="point_categorie.title"
              :value="point_categorie.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <div class="add-button admin-button"
          style="float: right;"
          title="Ajouter un point d'intérêt"
          @click="deletePoint">
            <i class="icon-trash"></i>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="6">
          <el-form-item
          label="Lien texte">
            <el-input v-model="point.link_txt"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item
          label="lien url">
            <el-input v-model="point.link_url"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="26">
        <el-col>
          <el-form-item label="Photos">
            <draggable
            :options="{
              draggable: '.img-display'
            }"
            class="img-display-list"
            v-model="point.media">
              <img class="img-display"
              v-for="media in point.media"
              :src="media.thumb_url">
            </draggable>
            <div class="img-display-wrap img-select-icon"
            @mousedown.prevent
            @click="choosePhoto">
              <icon name="image"
              class="color: #c33;"
              scale="3"></icon>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="flex: 1; overflow: hidden;">
        <el-col :md="24" style="height: 100%; overflow: hidden;">
          <v-quill v-model="point.descr"></v-quill>
        </el-col>
      </el-row>
    </el-form>
    <el-button
    slot="footer"
    :loading="saving"
    :disabled="saving"
    style="width: 100%;" type="primary"
    @click="onSave">OK</el-button>
  </el-dialog>
</transition>
</template>

<script>
import { EventBus } from '@/components/event_bus.js'

export default {
  name: 'PointDialog',
  props: {
    point_categories: {
      type: Object
    },
    point: {
      type: Object
    }
  },
  data () {
    return {
      baseUrl: global.baseUrl,
      saving: false,
      file_path: '',
      logo_path: '',
    }
  },
  mounted () {


  },
  methods: {
    setCat: function() {
      var cat = this.point_categories.ref[this.point.categorie]
      this.point.cat_title = cat.title
      this.point.cat_color = cat.color
    },
    onClose: function() {
      this.$emit('close')
    },
    choosePhoto: function(){
      var vm = this

      // console.log('choosePhoto')

      EventBus.$emit('mediamanager', {
        format_filter: true,
        formats_list: {
          point: true,
          thumbnail: true
        },
        type_filter: true,
        multi_select: true,
        types_list: {
          image: true
        },
        milieu_id: vm.point.milieu_id,
        point_id: vm.point.id,
      })

      EventBus.$once('mediamanager_end', function(media_list) {
        console.log('media_list', media_list)
        if(media_list) {
          // retrieve current media order
          var current_media_order = {}

          vm.point.media.forEach(function(m, i){
            console.log('m.media_id, i', m.media_id, i)
            current_media_order[m.media_id] = i+1
          })

          // apply order in new list if media still present
          media_list.sort(function(m1, m2){
            var m1_order = (current_media_order[m1.media_id] || (m1.media_id*1000))
            var m2_order = (current_media_order[m2.media_id] || (m2.media_id*1000))

            return m1_order-m2_order
          })
          media_list.forEach(function(m){
            m.point_url = global.getImageFormat(global.baseUrl+m.file_path, 'point')+'?'+new Date().getTime()
          })

          vm.point.media = media_list
        }
      })
    },
    onSave: function(){
      var vm = this
      console.log('point', vm.point)
      var media_list = []

      vm.point.media.forEach(function(m){
        console.log('vm.point.media', m)
        media_list.push({
          media_id: m.media_id,
          original: m.original,
          legend: m.legend
        })
      })


      this.$http.post(

        global.ajaxAdminUrl+"?action=set_point_data", {
          token: global.token,
          milieu_id: vm.point.milieu_id,
          point_id: vm.point.id,
          photo_id: vm.point.photo_id,
          categorie: vm.point.categorie,
          title: vm.point.title,
          descr: vm.point.descr,
          link_txt: vm.point.link_txt,
          link_url: vm.point.link_url,
          media: media_list,
          is_new: vm.point.is_new
        })
      .then(function(data){
        var resp = data.body
        var point_id = Number(data.point_id)

        if(resp.point_id != point_id && point_id > 0) {
          var milieu = 

          vm.point.id = point_id
        }

        vm.point.media.forEach(function(m){
          // m.point_url = m.point_url.split('?')[0]+'?'+new Date().getTime()
          m.point_url = global.getImageFormat(global.baseUrl+m.file_path, 'point')+'?'+new Date().getTime()
          m.thumb_url = m.thumb_url.split('?')[0]+'?'+new Date().getTime()
          console.log('media', m)
        })

        this.$message({
          message: 'Point sauvegardé',
          type: 'success'
        })

        vm.$emit('save', vm.point)

        this.saving = false
        vm.onClose()

      },function(data){
        this.saving = false
      })
    },
    deletePoint: function() {
      var vm = this
      this.$confirm('Êtes-vous sûr de vouloir supprimer le point?', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
      })
      .then(function(){
        vm.$emit('delete', vm.point)
        vm.onClose()
      }, function(data){
        // cancel
      })
    },
  }
}
</script>
<style type="text/css">
/*.img-display {
  width: 100%;
  height: 100%;
  width: 103px;
  height: 103px;
  border: 1px solid #d8dce5;
  border-radius: 4px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #c2c9d4;
  cursor: pointer;
}

.img-display > svg{
  margin: auto;
}

.img-display > img{
  max-width: 100%;
  max-height: 100%;
}*/

.point-dialog .img-display-wrap {
  position: relative;
  width: 103px;
  height: 103px;
  border: 1px solid #d8dce5;
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  overflow: hidden;
}

.point-dialog .img-display {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #c2c9d4;
  cursor: pointer;
  background-size: cover;
  
  width: 103px;
  height: 103px;
  border: 1px solid #d8dce5;
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  overflow: hidden;
}

.point-dialog .img-display > img{
  max-width: 100%;
  max-height: 100%;
}

.point-dialog .img-display-wrap > svg{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.point-dialog .img-drag-handle {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  background-color: #ccc;
  border-color: #aaa;
  z-index: 999;
  border-width: 0 0 1px 1px;
  border-radius: 0 0 0 4px;
  opacity: 0.75;
  cursor: move;
}

.point-dialog .img-select-icon {
  cursor: pointer;
}

.point-dialog .el-dialog__body {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px 20px;
}

.point-dialog .quill-wrap {
}

.el-tabs__content {
  overflow: visible;
}

.el-select-dropdown {
  z-index: 99999 !important;
}

.point-dialog .admin-button {
  width: 40px;
  height: 40px;
}


.point-dialog .el-dialog {
  height: 80%;
  min-height: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.point-dialog .el-dialog__body {
  overflow: hidden;
  flex: 1;
}

.point-dialog .quill-wrap {
  height: 100%;
}

.point-dialog .quill-editor {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.point-dialog .ql-container {
  flex: 1;
  overflow: hidden;
}
</style>
