<template>
  <div class="quill-wrap">
    <div class="quill-code"
    v-show="showCode">
      <div class="code-toolbar ql-snow" style="position: relative;">
        <el-button size="small"
        class="toggle-code"
        @click="switchToText">
          <!-- <icon name="code"></icon> -->
          <svg viewBox="0 0 18 18">
            <polyline class="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline>
            <polyline class="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline>
            <line class="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line>
          </svg>
        </el-button>
      </div>
      <textarea @input="$emit('input', $event.target.value)">{{ contentCode }}</textarea>
    </div>
    <quill-editor
    v-show="!showCode"
    class="editor"
    ref="textEditor"
    :value="value"
    @input="$emit('input', $event)"
    @ready="onEditorReady($event)"
    :options="editorOption">
      <!-- <div id="toolbar" slot="toolbar" style="position: relative; padding: 0;" >

        <el-button size="small"
        class="toggle-code"
        @click="switchToCode">
          <icon name="code"></icon>
        </el-button>

      </div> -->

      <!-- <div id="toolbar" slot="toolbar" style="position: relative; padding: 0;" >
        <span class="ql-formats">
          <select class="ql-font"></select>
          <select class="ql-header"></select>
          <select class="ql-size"></select>
        </span>

        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
        </span>

        <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>

        <span class="ql-formats">
          <button class="ql-blockquote"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-indent" value="-1"></button>
          <button class="ql-indent" value="+1"></button>
          <select class="ql-align"></select>
        </span>

        <span class="ql-formats">
          <button class="ql-link"></button>
          <button class="ql-image"></button>
          <button class="ql-video"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-clean"></button>
        </span>

        <el-button size="small"
        class="toggle-code"
        @click="switchToCode">
          <icon name="code"></icon>
        </el-button>
      </div> -->

    </quill-editor>

<!--     <v-mediamanager
    ref="mediamanager"
    @input="onMediaSelect"
    :formats="formats"
    :baseUrl="baseUrl">
    </v-mediamanager> -->
  </div>
</template>
<script>
import hljs from 'highlightjs'
import beautify from 'js-beautify'
import VueQuillEditor, { Quill } from 'vue-quill-editor'
// import { ImageDrop } from 'quill-image-drop-module'
// import ImageResize from 'quill-image-resize-module'
// Quill.register('modules/imageDrop', ImageDrop)
// Quill.register('modules/imageResize', ImageResize)
window.Quill = Quill
// import ImageResize from 'quill-image-resize-module'
// import { ImageDrop } from 'quill-image-drop-module'
// Quill.register('modules/imageDrop', ImageDrop)
// console.log('Quill', Quill)
// Quill.register('modules/imageResize', ImageResize)
// console.log(ImageResize)

var Delta = Quill.imports.delta

// console.log('Delta', Delta)
// console.log('VueQuillEditor', VueQuillEditor)
// console.log('MediaManager', MediaManager)
var Link = Quill.import('formats/link')
console.log('Link', Link)

// console.log(Quill.imports)

export default {
  props: {
    value: {
      type: String,
      required: true,
      default: () => ('')
    },
    // formats: {
    //   type: Object
    // }
  },
  data () {
    return {
      // showCode: true,
      baseUrl: global.baseUrl,
      showCode: false,
      contentCode: '',
      form: {
        title: '',
        descr: '',
      },
      editorOption: {
        placeholder: "",
        bounds: ".quill-wrap",
        modules: {
          // toolbar: '#toolbar' // Selector for toolbar container
          toolbar: {
            container: [
              [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
              // [{ 'font': [] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              // [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              // [{ 'script': 'sub' }, { 'script': 'super' }],
              // [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              // [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              // ['link', 'image', 'video'],
              ['link'],
              ['code']
            ],
            // handlers: {
            //   'link': function(value) {
            //     if (value) {
            //       var href = prompt('Enter the URL');
            //       this.quill.format('link', href);
            //     } else {
            //       this.quill.format('link', false);
            //     }
            //   }
            // },
          }
        }
      }
    }
  },
  mounted () {
    var vm = this
    // this.quill = this.$refs.textEditor.quill
    // console.log('this.quill', this.quill)

    var toolbar = this.quill.getModule('toolbar')

    toolbar.addHandler('code', function() {
      // console.log('code')
      vm.switchToCode()
    })

    // var codeButton = document.querySelector('.ql-code')
    // var codeBlock = codeButton.parentNode

    // codeButton.innerHTML = '<svg version="1.1" role="presentation" width="16" height="14.933333333333334" viewBox="0 0 1920 1792" class="fa-icon"><path d="M617 1399l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23l-393 393 393 393q10 10 10 23t-10 23zM1208 332l-373 1291q-4 13-15.5 19.5t-23.5 2.5l-62-17q-13-4-19.5-15.5t-2.5-24.5l373-1291q4-13 15.5-19.5t23.5-2.5l62 17q13 4 19.5 15.5t2.5 24.5zM1865 983l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23t-10 23z"></path></svg>'

    // console.log('codeBlock', codeBlock)
    // customButton.addEventListener('click', function() {
    //   var range = vm.quill.getSelection();
    //   if (range) {
    //     vm.quill.insertText(range.index, "Ω");
    //   }
    // });
  },
  computed: {
    quill() {
      return this.$refs.textEditor.quill
    }
  },
  methods: {
    onEditorReady: function(e){
      // var editor = e.editor
      // console.log('onEditorReady', e)
      // console.log('onEditorReady', editor, e)
      e.getModule('toolbar').addHandler('image', this.imageHandler)

      let quillContainer = e.container
      let qlEditor = quillContainer.querySelector('.ql-editor')

      // qlEditor.addEventListener('click', function(e){
      //   console.log('qlEditor click', e)
      // }, false)
    },
    imageHandler: function(e){
      console.log('imageHandler', e)

      this.$refs.mediamanager.open()
    },
    onMediaSelect(file_id, file) {
      // console.log('onMediaSelect', file_id, file.file_path)
      // console.log('onMediaSelect', file_id, file)

      var delta = new Delta()
      console.log('delta', delta)
      console.log(this.quill)
      let range = this.quill.getSelection(true)
      console.log('range', range)

      this.quill.updateContents(delta
        .retain(range.index)
        .delete(range.length)
        .insert({ image: global.baseUrl + file.file_path }))
    },
    onCodeChange: function(e){
      var html = e.target.value
      this.quill.pasteHTML(html)
    },
    switchToText: function(){
      this.showCode = false
    },
    switchToCode: function(){
      this.contentCode = beautify.html_beautify(
        this.quill.container.children[0].innerHTML
      )
      this.showCode = true
    }
  }
}
</script>
<style>
.ql-snow .ql-picker-label:before {
  position: absolute;
}

.quill-wrap {
  /*
  display: flex;
  flex-direction: column;
  */
  height: 100%;
}

.quill-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}


.quill-code textarea,
.ql-container {
  /*min-height: 200px;*/
}

.ql-toolbar.ql-snow {
  padding: 0 8px;
  padding-top: 2px;
  position: relative;
  padding-right: 20px;
}

.ql-container .quill-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.quill-code {
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 0;
  overflow-y: auto;
  overflow-x: auto;
  background: #fff;
  color: #4d4d4c;
  display: flex;
  flex-direction: column;
}

.quill-code > .code-toolbar{
  padding: 0px;
  border-bottom: 1px solid #ccc;
  height: 40px;
}

.quill-code > textarea{
  flex: 1;
  padding: .5em;
  display: block;
  margin: 0;
  white-space: pre;
  line-height: 24px;
  border: 0;
  resize: none;
  white-space: pre-line;
}

.quill-code .code-toolbar .toggle-code ,
.quill-editor #toolbar .toggle-code {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  border: 1px solid #d8dce5;
  border-width: 0 0 0 1px;
  padding: 0px;
  border-radius: 0;
  text-align: center;
  width: 40px;
  line-height: 45px;
}

.quill-code .code-toolbar .toggle-code > span,
.quill-editor .toggle-code > span {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
    right: 4px;
    position: absolute;
}
/*
.ql-showHtml:after {
  content: "[source]";
}

.quill-code2 {
  width: 100%;
  margin: 0px;
  background: rgb(29, 29, 29);
  box-sizing: border-box;
  color: rgb(204, 204, 204);
  font-size: 15px;
  outline: none;
  padding: 20px;
  line-height: 24px;
  font-family: Consolas, Menlo, Monaco, 'Courier New', monospace;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
  display:none;
}
*/

.ql-toolbar.ql-snow {
  line-height: 34px;
  height: 40px;
}

.ql-toolbar.ql-snow .ql-picker,
.ql-toolbar.ql-snow .ql-picker-label,
.ql-snow.ql-toolbar button {
  line-height: 25px;
  height: 25px;
}

.ql-toolbar.ql-snow .ql-picker,
.ql-toolbar.ql-snow .ql-picker-label,
.ql-snow.ql-toolbar button,
.ql-snow .ql-picker-label:before {
  line-height: 30px;
  height: 30px;
}

.ql-snow.ql-toolbar button {
  width: auto;
}

.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
  width: 30px;
}

.ql-toolbar > span.ql-formats:last-child {
  margin-right: -8px;
  float: right;
  display: block;
  height: 100%;
}

.ql-snow.ql-toolbar button.ql-code {
  height: 100%;
  border-width: 0 0 0 1px;
  border-left: 1px solid #d8dce5;
  padding: 0 8px;
  border-radius: 0;
  text-align: center;
  width: 40px;
  line-height: 45px;
  position: absolute;
  float: none;
  top: 0;
  right: 0;
}

.ql-snow .ql-code .ql-stroke {
  stroke: #44444480;
}

.ql-code svg,
.toggle-code svg {
  width: 23px;
  display: inline-block;
  height: 100%;
}

.ql-container {
  height: auto;
  flex: 1;
  overflow: visible;
  /*padding: 12px 0;*/
}

.ql-editor {
  /*padding: 0 15px;*/
}
</style>