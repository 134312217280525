<template>
  <div class="swiper-container"
  ref="swiper"
  @click.capture="maybePrevent"
  @mousedown.prevent.stop="onMousedown"
  @touchstart.prevent.stop="onMousedown"
  :class="{
    isClosed: !isOpened,
    isOpened: isOpened,
    isMoving: isMoving,
    isMobile: isMobile
  }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    threshold: {
      default: 5
    }
  },
  data () {
    return {
      position: {
        top: '0px',
        left: '0px'
      },
      isMoving: false,
      // isClosed: false,
      isOpened: true,
      isMobile: false,
      padding: 10
    }
  },
  mounted: function () {
    var swiper = this.$refs.swiper
    var rect = swiper.getBoundingClientRect()
    // this.dimension = rect
    this.dimension = {
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      width: rect.width,
      x: rect.x,
      y: rect.y
    }

    this.hasMoved = false

    var deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width

    if (parseInt(deviceWidth) <= 567) {
      this.isMobile = true
      this.isOpened = false
    }

    // this.isMobile = true

    // if (this.isOpened) {
    //   this.open()
    // }
    // else {
    //   this.close()
    // }
  },
  methods: {
    maybePrevent: function (e) {
      if(this.hasMoved) {
        e.stopImmediatePropagation()
        e.preventDefault()
        this.hasMoved = false
      }
    },
    bind: function () {
      window.addEventListener('touchend', this.onMouseup)
      window.addEventListener('touchcancel', this.onMouseup)
      window.addEventListener('touchleave', this.onMouseup)
      window.addEventListener('mouseup', this.onMouseup)

      window.addEventListener('touchmove', this.onMousemove)
      window.addEventListener('mousemove', this.onMousemove)
    },
    unbind: function () {
      window.removeEventListener('touchend', this.onMouseup)
      window.removeEventListener('touchcancel', this.onMouseup)
      window.removeEventListener('touchleave', this.onMouseup)
      window.removeEventListener('mouseup', this.onMouseup)

      window.removeEventListener('touchmove', this.onMousemove)
      window.removeEventListener('mousemove', this.onMousemove)
    },
    onMousedown: function (e) {
      this.currentPosition = this.initialPosition = this.getEventPosition(e)

      this.isMoving = true
      this.bind()
      this.$emit('onStart', e, this.currentPosition)
      // this.anim()
    },
    onMouseup: function (e) {
      this.unbind()
      this.isMoving = false
      var distance_left = this.initialPosition.left - this.currentPosition.left

      this.$emit('onEnd', e, this.currentPosition)
    },
    onMousemove: function (e) {
      this.currentPosition = this.getEventPosition(e)
      this.$emit('onMove', e, this.currentPosition)
    },
    getEventPosition: function (e) {
      let posx, posy
      e = e || window.event

      if (e.touches && e.touches[0]) {
        e = e.touches[0]
      }
      else if (e.changedTouches && e.changedTouches[0]) {
        e = e.changedTouches[0]
      }

      if (e.clientX || e.clientY) {
        posx = e.clientX
        posy = e.clientY
      }
      else if (e.pageX || e.pageY) {
        posx = e.pageX - document.body.scrollLeft - document.documentElement.scrollLeft
        posy = e.pageY - document.body.scrollTop - document.documentElement.scrollTop
      }

      return {
        top: posy,
        left: posx
      }
    }
  }
}
</script>
<style>

</style>
