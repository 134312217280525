<template>
  <el-dialog
  class="download-dialog dialog_flex"
  @close="onClose"
  :before-close="onClose"
  :visible="visible"
  :append-to-body="true"
  width="80%">
    <el-row
    class="main_flex"
    v-loading="loading"
    style="margin-top: 10px;"
    :gutter="24">
      <el-col :md="8"
      v-for="download in downloads"
      :key="download.id">
        <div
        class="list_entry">
          <img :src="baseUrl + download.file_path">
          <div class="licence_block">
            <b v-html="download.milieu_title"></b><br>
            <i
            v-if="download.credits_photo!=''"
            >Crédits: <span v-html="download.credits_photo"></span>, </i>
            <i
            v-if="download.licence_photo!=0">
            licence:
              <a target="_blank"
              :href="download.licence_link"
              v-html="download.licence_title"></a>
            </i>
            <a class="el-button el-button--default"
            :href="download.download_url" download
            style="float: right;
            background-color: #C22C26;
            font-weight: bold;
            color: #fff;
            text-decoration: none;"
            >Télécharger</a>
          </div>

        </div>
      </el-col>
    </el-row>
    <el-row
    slot="footer"
    :gutter="0">
      <el-col :md="24">
        <div class="download-head">
          <div style="padding: 10px;">
            <div>
              <div class="photo-icon">
                <img style="width: 50px;" src="static/icons/photo.png">
              </div>
              <h1>Bonus Fond d'écrans</h1>
            </div>
            <div><b>Une sélection de fonds d'écrans offerts généreusement par nos photographes bénévoles.</b></div>
          </div>
        </div>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
import { EventBus } from '@/components/event_bus.js'

export default {
  name: 'SearchDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    point_categories: {
      type: Object
    },
  },
  data () {
    return {
      baseUrl: global.baseUrl,
      downloads: [],
      loading: true
    }
  },
  mounted () {
    var vm = this
    this.getDownloads()
  },
  methods: {
    onClose: function() {
      // console.log('update:visible', false)
      this.$emit('update:visible', false)
    },
    onChange: function(val) {
      var vm = this

      vm.point_categories.list.forEach(function(c){
        vm.visible_cat[c.id] = false
      })

      vm.cat.forEach(function(c_id){
        vm.visible_cat[c_id] = true
      })

      console.log('onChange', val, vm.visible_cat)
    },
    getDownloads: function() {
      this.loading = true
      this.$http.post(
        global.ajaxUrl+"?action=get_downloads", {

        })
      .then(function(data){
        var resp = data.body
        resp.downloads.forEach(function(d){
          d.download_url = global.baseUrl+'download.php?url='+d.file_path
        })
        this.downloads = resp.downloads
        this.loading = false

      },function(data){

      })
    },
    download: function(url) {

    }
  }
}
</script>
<style type="text/css">

.download-dialog .el-dialog {
  background-color: #e5e2b1;
  border-radius: 5px;
}
/*
.download-dialog .el-dialog__header {
  padding: 0;
  height: 0;
}
*/
.download-dialog .main_flex {
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  overflow: auto;
}

.download-dialog .list_entry {
  display: inline-block;
  position: relative;
}

.download-dialog .list_entry img {
  width: 100%;
}

.download-dialog.el-dialog__wrapper.dialog_flex .el-dialog__body {
  padding: 0 12px 12px;
  /*overflow: visible;*/
}

.download-dialog .el-dialog__headerbtn {
  position: absolute;
  top: -16px;
  right: 12px;
  color: #fff;
  background-color: #af1624;
  font-size: 32px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  transition: background-color .2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 500;
}

.download-dialog .el-dialog__headerbtn:hover {
  background-color: #739e30;
}

.download-dialog .el-dialog__close.el-icon.el-icon-close {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

.download-dialog .el-icon-close:before {
  content: "\E908";
}

.download-dialog .download-head {
  background-color: #739e30;
  color: #fff;
  margin-left: -30px;
  margin-right: -30px;
  text-align: left;
}

.download-dialog .photo-icon {
  display: inline-block;
  background-color: #fff;
  border-radius: 50px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  padding-top: 10px;
  text-align: center;
  line-height: 70px;
  float: left;
}

.download-dialog .download-head h1 {
  display: inline-block;
  height: 70px;
  margin: 0 10px;
  line-height: 70px;
}

.download-dialog .download-head b {
  line-height: 40px;
  font-size: 20px;
}

.download-dialog .licence_block {
  position: absolute;
  bottom: 20px;
  color: #fff;
  background-color: #0008;
  padding: 5px;
  width: 100%;
}


</style>

