<template>
  <div class="main-container">
    <form
    v-if="($route.path === baseUrl+'admin' || $route.path === '/admin') && !logged"
    class="login_container"
    method="post"
    ref="login_form"
    :action="ajaxUrl + '?action=login'"
    @submit.prevent="login">
      <router-link
      class="clear-login-screen"
      tag="div" to="/">
          <i class="icon-cross"></i>
      </router-link>
      <div class="login_box">
        <div class="text-input">
          <input 
          v-model="username"
          type="text" name="username" placeholder="Nom d'utilisateur">
        </div>
        <div class="text-input">
          <input 
          v-model="password"
          type="password" name="password" placeholder="Mot de passe">
        </div>
        <div class="submit-input">
          <input
          type="submit"
          value="Connexion">
        </div>
      </div>
      <!-- <notifications group="auth" position="top center"/> -->
    </form>
    <div style="overflow: hidden;"
    :class="{
      horizontal: horizontal,
      horizontal2: horizontal2
    }">
      <div
      v-if="logged"
      class="main-toolbar">
        <div class="milieu_toolbar"
        v-if="current_milieu && !current_milieu.blur">

          <div class="save-button admin-button"
          @mousedown.prevent
          style="float: right;"
          title="Sauver le positionnement"
          @click="savePointsPosition">
            <i class="icon-save"></i>
          </div>

          <div class="add-button admin-button"
          @mousedown.prevent
          style="float: right;"
          title="Ajouter un point d'intérêt"
          @click="addPoint">
            <i class="icon-plus"></i>
          </div>
        </div>
        <div class="map_toolbar"
        v-else>

          <div class="save-button admin-button"
          @mousedown.prevent
          style="float: right;"
          title="Sauver le positionnement"
          @click="saveMilieuxPosition">
            <i class="icon-save"></i>
          </div>

          <div class="add-button admin-button"
          @mousedown.prevent
          style="float: right;"
          title="Ajouter un milieu"
          @click="addMilieu">
            <i class="icon-plus"></i>
          </div>
        </div>
        <div class="add-button admin-button"
        @mousedown.prevent
        style="float: right;"
        title="Déconnexion"
        @click="logout">
          <i class="icon-sign-out-alt"></i>
        </div>

        <div class="add-button admin-button"
        @mousedown.prevent
        style="float: right;"
        title="Configuration"
        @click="show_parameter_dialog=true">
          <i class="icon-cog"></i>
        </div>

        <label role="checkbox"
        @mousedown.prevent
        class="el-checkbox is-bordered"
        :class='{
          "is-checked": drag_mode
        }'
        style="background-color: rgb(255, 255, 255);
        height: 45px;
        margin: 5px;
        float: right;">
          <span aria-checked="mixed"
          @mousedown.prevent
          :class='{
            "is-checked": drag_mode
          }'
          class="el-checkbox__input">
            <span class="el-checkbox__inner"></span>
            <input
            aria-hidden="true"
            class="el-checkbox__original"
            v-model="drag_mode"
            value=""
            type="checkbox">
          </span>
          <span class="el-checkbox__label">Drag mode</span>
        </label>
      </div>

      <v-milieux
      ref="milieux"
      @pointOpen="pointOpen($event)"
      @backgroundLoad="backgroundLoad($event)"
      @transitionend="initMilieu"
      v-if="milieux_loaded"
      :point_categories="point_categories"
      :current_index="current_index"
      :prev_index.sync="prev_index"
      :next_index.sync="next_index"
      :logged="logged"
      :milieux="milieux"
      :drag_mode="drag_mode"
      ></v-milieux>

      <v-panel
      ref="panel"
      @transitionend="setCurrentMilieu"
      v-if="current_milieu"
      :milieu="current_milieu"
      :licences="licences"
      :hidden="panel_hidden"
      :logged="logged"
      @edit="editMilieu"
      ></v-panel>
      <v-hud
      v-if="milieux_loaded"
      @mapClick="mapClick"
      @fullscreen="fullscreen"
      @soundtoggle="soundtoggle"
      @prevSlide="prevSlide"
      @nextSlide="nextSlide"
      @help_hide="help_show = false"
      @help_show="help_show = true"
      :hidden="HUD_hidden"
      :nosound="nosound"
      :current_milieu="current_milieu"
      ></v-hud>
      <transition name="fade">
        <v-map
        ref="milieu_map"
        v-if="milieux_loaded"
        v-show="map_show"
        @milieuClick="milieuClick($event)"
        @goto="goto"
        :milieu_categories="milieu_categories"
        :point_categories="point_categories"
        :milieux="milieux"
        :current_milieu_index="current_index"
        :drag_mode="drag_mode"
        ></v-map>
      </transition>
      <transition name="fade">
        <v-help
        v-if="milieux_loaded"
        v-show="help_show"
        :point_categories="point_categories"></v-help>
      </transition>
      <transition name="slide_right">
        <v-point
        class="slide_right"
        @close="pointClose"
        @edit="editPoint"
        v-show="point_opened"
        :logged="logged"
        :opened.sync="point_opened"
        :point_categories="point_categories"
        :point="current_point">
        </v-point>
      </transition>
      <transition name="fade">
        <v-milieu-dialog
        v-if="logged && milieux_loaded && show_milieu_dialog"
        :milieu="edited_milieu"
        :milieu_categories="milieu_categories"
        :licences="licences"
        @save="onSaveMilieu"
        @delete="onDeleteMilieu"
        @close="show_milieu_dialog = false"
        ref="milieu-dialog"></v-milieu-dialog>
      </transition>
      <transition name="fade">
        <v-point-dialog
        v-if="logged && milieux_loaded && show_point_dialog"
        :point="edited_point"
        :point_categories="point_categories"
        @delete="onDeletePoint"
        @save="onSavePoint"
        @close="show_point_dialog = false"
        ref="point-dialog"></v-point-dialog>
      </transition>
      <transition name="fade">
        <v-parameter-dialog
        v-if="logged && milieux_loaded && show_parameter_dialog"
        :milieu_categories="milieu_categories"
        :point_categories="point_categories"
        :licences="licences"
        @close="show_parameter_dialog = false"
        ref="parameter-dialog"></v-parameter-dialog>
      </transition>
      <transition name="fade">
        <v-mediamanager
        ref="mediamanager"
        v-if="logged && milieux_loaded && show_mediamanager"
        v-show="show_mediamanager"
        @input="onMediaSelect"
        @close="onMediamanagerClose"
        :multi_select="media_multi_select"
        :formats="media_formats"
        :format_filter="format_filter"
        :type_filter="type_filter"
        :formats_list="formats_list"
        :types_list="types_list"
        :current_file_id="current_file_id"
        :current_milieu_id="current_milieu_id"
        :current_point_id="current_point_id"
        :current_milieu_categorie_id="current_milieu_categorie_id"
        >
        </v-mediamanager>
      </transition>
    </div>
  </div>
</template>
<script>
import {Howl, Howler} from 'howler'
import { EventBus } from '@/components/event_bus.js'

export default {
  data () {
    return {
      initial_index: 0,
      current_index: 0,
      prev_index: 0,
      next_index: 0,
      milieuAI: false,
      milieux_loaded: false,
      milieux: [],
      edited_milieu: null,
      edited_point: null,
      new_milieu: null,
      current_milieu: {
        pointAI: 0,
        photo_id: 0,
        sound_id: 0,
        logo_id: 0,
        logoPartenaire: 0,
        logo_url: '',
        categorie: 0,
        positionX: 50,
        positionY: 50,
        title: '',
        description: '',
        enjeux: '',
        // credits: '',
        // licence: '',
        credits_photo: '',
        licence_photo: '',
        credits_sound: '',
        licence_sound: '',
        photo_path: '',
        sound_path: '',
        logo_path: '',
      },
      current_point: {
        id: 0,
        categorie: 0,
        positionX: 50,
        positionY: 50,
        title: '',
        descr: '',
        media: []
      },
      milieu_categories: {
        list: [],
        ref: {}
      },
      point_categories: {
        list: [],
        ref: {}
      },
      licences: {
        list: [],
        ref: {}
      },
      HUD_hidden: true,
      panel_hidden: true,
      map_show: true,
      help_show: false,
      point_opened: false,
      nosound: false,
      sound_volume: 0.5,
      sound_fadetime_in: 2000,
      sound_fadetime_out: 1500,

      target_ratio: 16/9,
      target_ratio2: 1076/977,
      horizontal: false,
      horizontal2: false,

      // logged: false,
      baseUrl: global.baseUrl,
      logged: global.logged,
      ajaxUrl: global.ajaxUrl,
      username: '',
      password: '',

      // media selection
      show_milieu_dialog: false,
      show_point_dialog: false,
      show_parameter_dialog: false,
      show_mediamanager: false,
      format_filter: false,
      formats_list: {},
      type_filter: false,
      types_list: {},
      current_file_id: 0,
      current_milieu_id: 0,
      current_milieu_categorie_id: 0,
      current_point_id: 0,
      media_multi_select: false,

      // point_drag_mode: false,
      // milieu_drag_mode: false,
      drag_mode: false,

      scheduled_point_id: null
    }
  },

  mounted () {
    var vm = this
    console.log('main mounted')
    window.addEventListener('resize', vm.setRatio.bind(this))
    window.addEventListener('start_app', vm.start_sound.bind(this))
    this.setRatio()
    this.init()

    EventBus.$on('session_end', function(){
      vm.logged = false
    })

    EventBus.$on('mediamanager', function(params){
      // console.log('params', params)
      if(params.format_filter) {
        vm.format_filter = true
        vm.formats_list = params.formats_list
      }
      else {
        vm.format_filter = false
      }

      if(params.type_filter) {
        vm.type_filter = true
        vm.types_list = params.types_list
      }
      else {
        vm.type_filter = false
        vm.types_list = {}
      }

      if(params.current_file_id) {
        vm.current_file_id = params.current_file_id
      }
      else {
        vm.current_file_id = 0
      }

      if(params.milieu_id) {
        vm.current_milieu_id = params.milieu_id
      }
      else {
        vm.current_milieu_id = 0
      }

      if(params.point_id) {
        vm.current_point_id = params.point_id
      }
      else {
        vm.current_point_id = 0
      }

      if(params.milieu_categorie_id) {
        vm.current_milieu_categorie_id = params.milieu_categorie_id
      }
      else {
        vm.current_milieu_categorie_id = 0
      }

      if(params.multi_select) {
        vm.media_multi_select = true
      }
      else {
        vm.media_multi_select = false
      }

      vm.$nextTick(function(){
        vm.show_mediamanager = true
      })
    })
  },
  beforeDestroy: function(){
    console.log('beforeDestroy')
    this.reset()
  },
  methods: {
    init: function(){
      console.log("main init", this.$route.path)
      if(this.$route.path === global.baseUrl+'admin'){
        console.log('true')
      }
      else {
        console.log('false')
      }
      this.current_index = this.initial_index
      this.reset()
      this.getMilieuList()
    },
    goto: function(data){
      console.log('main goto', data)
      var milieu = this.milieux_ref[data.milieu_id]
      this.milieuClick(milieu)
      this.scheduled_point_id = data.point_id
    },
    get_blank_milieu: function(){
      return {
        // id: ++this.milieuAI,
        id: 0,
        pointAI: 0,
        photo_id: 0,
        sound_id: 0,
        logo_id: 0,
        photo_path: '',
        sound_path: '',
        logo_path: '',
        // logoPartenaire: 0,
        logo_url: '',
        categorie: 1,
        positionX: 50,
        positionY: 50,
        title: '',
        description: '',
        enjeux: '',
        credits_photo: '',
        licence_photo: '',
        credits_sound: '',
        licence_sound: '',
        publish: 0,
        background_loaded: false,
        blur: false,
        show_markers: false,
        points: [],
        points_ref: {},
        deleted: 0,
        is_new: 1,
      }
    },
    get_blank_point: function(){

      var point = {
        milieu_id: this.current_milieu.id,
        id: ++this.current_milieu.pointAI,
        categorie: 1,
        positionX: 50,
        positionY: 50,
        title: '',
        descr: '',
        link_txt: '',
        link_url: '',
        media: [],
        deleted: 0,
        is_new: 1
      }

      return point
    },
    savePointsPosition: function(){
      var positions = []

      this.current_milieu.points.forEach(function(p){
        positions.push({
          id: p.id,
          x: p.positionX,
          y: p.positionY
        })
      })

      this.$http.post(
        global.ajaxAdminUrl+"?action=set_points_position", {
          milieu_id: this.current_milieu.id,
          positions: positions,
          token: global.token
        })
      .then(
        function(data){
          var resp = data.body

          this.$message({
            message: 'Position des points sauvegardée',
            type: 'success'
          })
        },
        function(data){

        }
      )

    },
    addPoint: function(){
      // var point = this.get_blank_point()
      // this.current_milieu.points.push(point)

      this.edited_point = this.get_blank_point()
      this.show_point_dialog = true
    },
    onDeletePoint: function(point){
      var vm = this
      vm.$http.post(
        global.ajaxAdminUrl+"?action=del_point", {
          milieu_id: point.milieu_id,
          point_id: point.id,
          token: global.token
        }, 
        {

        })
      .then(function(data){
        point.deleted = 1
        vm.pointClose()
      }, function(data){
          //error
      })
    },
    onSavePoint: function(point){
      if(point.is_new === 1) {
        var milieu = this.milieux_ref[point.milieu_id]
        milieu.points.push(point)
        milieu.points_ref[point.id] = point
        point.is_new = 0
      }
    },

    saveMilieuxPosition: function(){
      var positions = []

      this.milieux.forEach(function(m){
        if(m.deleted===0){
          positions.push({
            id: m.id,
            x: m.positionX,
            y: m.positionY
          })
        }
      })

      this.$http.post(
        global.ajaxAdminUrl+"?action=set_milieux_position", {
          positions: positions,
          token: global.token
        })
      .then(
        function(data){
          var resp = data.body

          this.$message({
            message: 'Position des milieux sauvegardée',
            type: 'success'
          })
        },
        function(data){

        }
      )
    },
    addMilieu: function(){
      this.edited_milieu = this.get_blank_milieu()
      this.show_milieu_dialog = true
    },
    onSaveMilieu: function(milieu){
      if(milieu.is_new === 1) {
        milieu.is_new = 0
        this.milieux.push(milieu)
        this.milieux_ref[milieu.id] = milieu
      }

      if(this.current_milieu.sound_path_tmp !== this.current_milieu.sound_path) {
        var sound = this.current_milieu.sound
        if(sound){
          console.log('sound', sound)
          sound.stop()
          this.current_milieu.sound_path_tmp = this.current_milieu.sound_path
          this.current_milieu.sound_active = false
          this.current_milieu.sound = null

          this.start_milieu_sound()
        }
      }
    },
    onDeleteMilieu: function(milieu){
      var vm = this
      
      vm.$http.post(
        global.ajaxAdminUrl+"?action=del_milieu", {
          milieu_id: milieu.id,
          token: global.token
        }, 
        {

        })
      .then(function(data){
        this.milieux.splice(this.current_index , 1)
        // if(this.current_index > this.milieux.length-1){
        //   console.log('nex_index')
        this.current_index = this.getPrevIndex(this.current_index)
        this.next_index = this.getNextIndex(this.current_index)
        this.prev_index = this.getPrevIndex(this.current_index)
        // }
        this.nextSlide()
      }, function(data){
        //error
      })
      
    },
    start_sound: function(){
      if(!this.sound_started && this.milieux_loaded){
        this.start_milieu_sound()
      }
      this.sound_started = true
      global.sound_started = true
    },
    start_milieu_sound: function(){
      var vm = this
     if(!vm.current_milieu) return
     if(!vm.current_milieu.sound){
        var sound_path = global.baseUrl + vm.current_milieu.sound_path

        vm.current_milieu.sound_path_tmp = vm.current_milieu.sound_path
        vm.current_milieu.sound = new Howl({
          src: [sound_path],
          // autoplay: true,
          loop: true,
          html5: true,
          volume: 0
        })

      }
      if(!vm.current_milieu.sound_active) {
        vm.current_milieu.sound.play()
        vm.current_milieu.sound.fade(
          0, 
          vm.sound_volume, 
          vm.sound_fadetime_in)

        vm.current_milieu.sound_active = true
      }
    },
    setRatio: function(){
      var w_ratio = window.innerWidth/window.innerHeight
      this.horizontal = w_ratio>this.target_ratio
      this.horizontal2 = w_ratio>this.target_ratio2
    },
    milieuClick: function(milieu){
      var milieu_index = this.getMilieuIndex(milieu)
      if(this.current_index === milieu_index) {
        this.HUD_hidden = false
        this.panel_hidden = false
        if(this.current_milieu)
        this.current_milieu.blur = false
        this.map_show = false
        return
      }
      if(this.current_milieu)
      this.current_milieu.blur = false
      this.map_show = false
      this.current_index = milieu_index
      this.new_milieu = milieu
      this.setNewMilieu()
      this.setCurrentMilieu()

      this.$refs.milieux.slideRightTo(this.current_index)
    },
    backgroundLoad: function(milieu){
      // console.log('backgroundLoad', milieu)
    },
    pointOpen: function(point){
      var vm = this
      vm.current_point = point
      // vm.$nextTick(function(){
        // requestAnimationFrame(function(){
          if(vm.current_milieu)
          vm.current_milieu.blur = true
          // requestAnimationFrame(function(){
            vm.HUD_hidden = true
            // requestAnimationFrame(function(){
              vm.panel_hidden = true
              vm.$nextTick(function(){
                // requestAnimationFrame(function(){
                  vm.point_opened = true
                // })
              })
      //       })
      //     })
      //   })
      // })
    },
    pointClose: function(point){
      var vm = this
      // vm.$nextTick(function(){
        // requestAnimationFrame(function(){
          if(vm.current_milieu)
          vm.current_milieu.blur = false
          // requestAnimationFrame(function(){
            vm.HUD_hidden = false
            // requestAnimationFrame(function(){
              vm.panel_hidden = false
              // requestAnimationFrame(function(){
                vm.point_opened = false
              // })
            // })
          // })
        // })
      // })
    },
    mapClick: function(){
      this.HUD_hidden = true
      this.panel_hidden = true
      this.map_show = true
      if(this.current_milieu)
      this.current_milieu.blur = true
    },
    onMediaSelect: function(media){
      this.show_mediamanager = false

      console.log('onMediaSelect', media)
    },
    fullscreen: function(){
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      else {
        document.body.requestFullscreen();
      }
    },
    soundtoggle: function(){
      // Change global volume.
      if (this.nosound) {
        Howler.volume(this.sound_volume)
        this.nosound = false
      }
      else {
        Howler.volume(0)
        this.nosound = true
      }
    },
    getPrevIndex: function(index){
      if(index === 0) {
        return this.milieux.length - 1
      }
      else {
        return index-1
      }
    },
    getNextIndex: function(index){
      if(index === this.milieux.length - 1) {
        return 0
      }
      else {
        return index+1
      }
    },
    prevSlide: function(){
      this.current_index = this.getPrevIndex(this.current_index)
      this.new_milieu = this.milieux[this.current_index]
      this.setNewMilieu()
      this.$refs.milieux.slideLeftTo(this.current_index)
    },
    nextSlide: function(){
      this.current_index = this.getNextIndex(this.current_index)
      this.new_milieu = this.milieux[this.current_index]
      this.setNewMilieu()
      this.$refs.milieux.slideRightTo(this.current_index)
    },
    setNewMilieu: function(){
      var vm = this
      if(!this.current_milieu) return
      this.HUD_hidden = true
      this.panel_hidden = true
      if(this.current_milieu){
        this.current_milieu.show_markers = false
        this.current_milieu.blur = false
      }

      if(vm.current_milieu && vm.current_milieu.sound) {
        var sound = vm.current_milieu.sound
        sound.fade(vm.sound_volume, 0, vm.sound_fadetime_out)
        setTimeout(function(){
          sound.stop()
        }, vm.sound_fadetime_out)
        this.current_milieu.sound_active = false
      }
      // setTimeout(function(){
        vm.getMilieuPoints()
      // }, 100)
    },
    setCurrentMilieu: function(){
      if(this.new_milieu){
        this.current_milieu = this.new_milieu
        // console.log(this.current_milieu)
        // this.current_milieu.addPoint()
        this.new_milieu = null
      }
    },
    initMilieu: function(){
      var vm = this

      vm.HUD_hidden = false
      vm.panel_hidden = false
      if(vm.current_milieu)
      vm.current_milieu.show_markers = true
      var current_milieu_index = this.getMilieuIndex(this.current_milieu)
      this.next_index = this.getNextIndex(current_milieu_index)
      this.prev_index = this.getPrevIndex(current_milieu_index)

      if(this.scheduled_point_id){
        var point = this.current_milieu.points_ref[this.scheduled_point_id]
        this.pointOpen(point)
        this.scheduled_point_id = null
      }

      vm.$nextTick(function(){
        if(global.sound_started){
          this.start_milieu_sound()
        }
      })
    },
    get_ref: function(arr, k) {
      var obj = {
        list: arr,
        ref: {}
      }

      arr.forEach(function(t){
        obj.ref[t[k]] = t
      })
      return obj
    },
    getMilieuIndex: function(milieu){
      return this.milieux.indexOf(milieu)
    },
    getMilieuList: function(){
      var vm = this
      this.next_index = 0
      this.prev_index = 0
      if(this.current_milieu){
        this.next_index = this.getNextIndex(this.current_milieu.i)
        this.prev_index = this.getPrevIndex(this.current_milieu.i)
      }

      var req = {}

      if(this.logged && global.token) {
        req.token = global.token
      }

      this.$http.post(
        global.ajaxUrl+"?action=get_all",
        req
      )
      .then(function(data){
          var resp = data.body
          if(resp.ok){
            this.media_formats = this.get_ref(resp.media_formats, 'name')
            
            global.getThumbFormat = function(url) {
              var thumb_size = vm.media_formats.ref['thumbnail']
              return global.getMediaFormat(url,
                thumb_size.width,
                thumb_size.height)
            }
            
            global.getImageFormat = function(url, format) {
              var thumb_size = vm.media_formats.ref[format]
              return global.getMediaFormat(url,
                thumb_size.width,
                thumb_size.height)
            }

            var milieux_ref = {}

            resp.milieux.forEach(function(m, i){
              // m.i = i
              m.background_loaded = false
              m.loaded = false
              if(i == vm.current_index) {
                m.blur = true
              }
              else {
                m.blur = false
              }
              m.show_markers = false
              m.photo_path_sized = global.getImageFormat(m.photo_path, 'milieu')
              m.points = []
              m.points_ref = {}

              m.pointAI = 0
              m.deleted = 0
              m.is_new = 0
              milieux_ref[m.id] = m

              vm.milieuAI =Math.max(vm.milieuAI, Number(m.id))
            })


            global.milieu_categories_AI = 0
            resp.milieu_categories.forEach(function(c, i){
              c.id = Number(c.id)
              c.new = 0
              c.deleted = 0
              c.map_visible = true
              global.milieu_categories_AI =Math.max(
                global.milieu_categories_AI,
                c.id)
            })

            global.point_categories_AI = 0
            resp.point_categories.forEach(function(c, i){
              c.id = Number(c.id)
              c.new = 0
              c.deleted = 0
              global.point_categories_AI =Math.max(
                global.point_categories_AI,
                c.id)
            })

            global.licences_AI = 0

            resp.licences.forEach(function(c, i){
              c.id = Number(c.id)
              c.new = 0
              c.deleted = 0
              global.licences_AI =Math.max(
                global.licences_AI,
                c.id)
            })
            
            resp.licences.unshift({
              id: 0,
              title: 'Aucune',
              link: '',
              free: 0,
              new: 1,
              deleted: 1
            })

            this.milieux = resp.milieux
            this.milieux_ref = milieux_ref

            this.milieu_categories = this.get_ref(resp.milieu_categories, 'id')
            this.point_categories = this.get_ref(resp.point_categories, 'id')
            this.licences = this.get_ref(resp.licences, 'id')

            global.milieu_categories = this.milieu_categories
            global.point_categories = this.point_categories
            global.licences = this.licences
            console.log('point_categories', JSON.stringify(this.point_categories.list))

            this.milieu_categories.list.forEach(function(c){
              c.map_visible = true
            })

            // this.current_milieu = this.milieux_ref[this.milieu_id]
            this.current_milieu = this.milieux[this.current_index]
            this.new_milieu = this.current_milieu
            this.setNewMilieu()
            this.initMilieu()
            this.milieux_loaded = true

            if(this.current_milieu){
              this.current_milieu.blur = true
            }
            this.HUD_hidden = true
            this.panel_hidden = true
          }
        }, function(data){

        });
    },
    getMilieuPoints: function(){
      var vm = this

      if(!this.new_milieu) return
      var milieu = this.milieux_ref[this.new_milieu.id]

      // console.log('milieu', milieu)
      if(!milieu.loaded) {
        this.$http.post(
          global.ajaxUrl+"?action=get_milieu_points", {
            milieu_id: this.new_milieu.id
          })
        .then(function(data){
            var resp = data.body
            if(resp.ok){
              var point_media_formats = this.media_formats.ref['point']
              milieu.points_ref = {}


              // setTimeout(function(){

                var images = new Array(), i = 0
                resp.points.forEach(function(p){
                  milieu.points_ref[p.id] = p

                  p.media.forEach(function(m){
                    m.thumb_url = global.getThumbFormat(global.baseUrl+m.file_path)

                    images[i] = new Image()
                    images[i].src = m.thumb_url
                    i++

                    m.point_url = global.getImageFormat(global.baseUrl+m.file_path, 'point')

                    // images[i] = new Image()
                    // images[i].src = m.point_url
                    // i++
                  })

                  p.is_new = 0

                  milieu.pointAI = Math.max(milieu.pointAI, Number(p.id))
                  milieu.points.push(p)
                })
              // }, 1000)


              vm.$nextTick(function(){
                milieu.loaded = true
              })
            }
          }, function(data){

          });
      }
      // this.milieu_id = parseInt(this.$route.params.id)
    },
    editMilieu: function(){
      this.edited_milieu = this.current_milieu
      this.show_milieu_dialog = true
    },
    editPoint: function(point){
      // console.log(point)
      // this.current_point = point
      this.edited_point = point
      this.show_point_dialog = true
    },
    login: function(){
        this.$http.post(
          global.ajaxUrl+"?action=login", {
            username: this.username,
            password: this.password
          })
        .then(function(resp){
          let token_str = resp.body.token
          let expiration = resp.body.expiration

          localStorage.setItem('id_token', token_str)
          localStorage.setItem('id_token_expiration', expiration)
          this.logged = true
          global.logged = true
          // this.$router.replace('/')

          this.init()
        },
        function(data){
          this.$message({
            message: 'Échec de la connexion.',
            type: 'error'
          })
        })
    },
    logout: function(){
      this.logged = false
      global.logged = false
      localStorage.removeItem('id_token')
      localStorage.removeItem('id_token_expiration')
      // this.$router.replace('/')
      this.init()
    },
    reset: function(){
      console.log('reset milieux', this.milieux)
      this.milieux.forEach(function(m){
        console.log('reset milieu', m, m.sound, m.sound_active)
        if(m.sound) {
          m.sound.stop()
        }
        // m.loaded = false
        m.sound = null
        m.sound_active = false
      })

      this.milieux = []
      // this.sound_started = false
      this.milieux_loaded = false
      // this.start_sound()
      this.mapClick()
    },
    onMediamanagerClose: function(){
      this.show_mediamanager = false
      EventBus.$emit('mediamanager_end', null)
    }
  }
}
</script>
<style type="text/css">

.slide_right {
  transition: transform 0.8s;
}

.slide_right.isMoving {
  transition: transform 0s;
}

.slide_right {

  /* Force Hardware Acceleration */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(1px);
  -moz-transform: translateZ(1px);
  -ms-transform: translateZ(1px);
  -o-transform: translateZ(1px);
  transform: translateZ(1px);

}

.slide_right-enter,
.slide_right-leave-to {
  -webkit-transform: translateX(99.99vw) !important;
  -moz-transform: translateX(99.99vw) !important;
  -ms-transform: translateX(99.99vw) !important;
  -o-transform: translateX(99.99vw) !important;
  transform: translateX(99.99vw) !important;
}

.slide_right-leave,
.slide_right-enter-to {
  -webkit-transform: translateX(0) !important;
  -moz-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  -o-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity .7s;
}

.fade2-enter, 
.fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/*** login ***/

.login_container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #382112;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 8000;
}

.login_box {
  background-color: #fff;
  display: inline-block;
  padding: 40px;
  min-width: 25%;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 768px) {
  .login_box {
    padding: 0px;
  }
}

.login_box > div {
  margin: 20px;
}

.login_box > div.text-input {
  background-color: #F2F2F2;
}

.login_box input {
  font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
  font-size: 1.25rem;
  height: 4rem;
  line-height: 4rem;
  border-radius: 2px;
  color: #777;
  background-color: transparent;
  border: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 1rem;
  min-width: 0%;
  outline: none;
}

.login_box > .submit-input > input {
  color: #fff;
  background-color: #4CC327;
  text-align: center;
  padding: 0;
  cursor: pointer;
}

.layout.logged {
  display: flex !important;
}

.layout-view {
  width: auto;
  flex: 1;
}

.notification-content {
  font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
  font-size: 1.25rem;
}

.el-dialog__wrapper {
  z-index: 9001 !important;
}

.v-modal {
  z-index: 9000 !important;
}

.el-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.clear-login-screen {
  position: fixed;
  top: 0;
  right: 0;
  color: #fff;
  border: 1px solid #fff8;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 0px;
  font-size: 30px;
  line-height: 30px;
  margin: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.map_toolbar,
.milieu_toolbar {
  display: inline-block;
}

</style>