<template>
  <router-view 
  :key="$route.fullPath" 
  ></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted: function(){
  }
}
</script>
<style>

html, body {
  margin: 0;
  padding: 0;
}

*, ::after, ::before {
    box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  background-color: #fff;
  color: #292b2c;
  color: #222426;
}

a {
  color: #0066cc;
}

.el-main {
  padding: 0;
}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}


.list-group-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  text-decoration: none;
  color: #292b2c;
}

.list-group-item:focus,
.list-group-item:hover {
  color: #464a4c;
  text-decoration: none;
  background-color: #f7f7f9;
}

.list-group-item > .title {
  flex: 1;
}

.list-group-item .el-button {
  width: 40px;
  text-align: center;
  padding: 0;
  line-height: 35px;
  height: 32px;
}

.list-group-item .button {
  margin: 0 5px;
}

.list-group-item .button:last-child {
  margin-right: 0;
}

/*** Icon font ***/
@font-face {
  font-family: 'icomoon';
  src:  url('static/fonts/icomoon.eot');
  src:  url('static/fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('static/fonts/icomoon.ttf') format('truetype'),
    url('static/fonts/icomoon.woff') format('woff'),
    url('static/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-wip:before {
  content: "\e911";
}
.icon-cog:before {
  content: "\e910";
}
.icon-sign-out-alt:before {
  content: "\e900";
}
.icon-save:before {
  content: "\e901";
}
.icon-photo-camera:before {
  content: "\e902";
}
.icon-email-envelope-outline:before {
  content: "\e903";
}
.icon-arrow_right:before {
  content: "\e904";
}
.icon-arrow_left:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-marker:before {
  content: "\e907";
}
.icon-cross:before {
  content: "\e908";
}
.icon-files_green:before {
  content: "\e909";
}
.icon-pencil:before {
  content: "\e90a";
}
.icon-warning2:before {
  content: "\e90b";
}
.icon-warning:before {
  content: "\e90c";
}
.icon-warningSmall:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-trash:before {
  content: "\e90f";
}


.admin-button {
  cursor: pointer;
  background-color: #75ADFF;
  background-color: #409EFF;
  color: #FFF;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  margin: 5px;
  width: 40px;
  height: 40px;
  font-size: 28px;
  line-height: 45px;
  /*border: 2px solid #aaa;*/
  text-shadow: 0 0 2px #0008;
  /*box-shadow: 0 0 2px #000;*/
}

.main-toolbar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5000;
}

/*
.edit-button {
  cursor: pointer;
  background-color: #75ADFF;
  color: #FFF;
  text-align: center;
  display: inline-block;
  float: right;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  font-size: 28px;
  line-height: 45px;
  text-shadow: 0 0 2px #0008;
}

.save-button {
  cursor: pointer;
  background-color: #75ADFF;
  color: #FFF;
  text-align: center;
  display: inline-block;
  float: right;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  font-size: 28px;
  line-height: 45px;
  text-shadow: 0 0 2px #0008;
}*/

.btn.close-btn {
  position: absolute;
  top: -16px;
  right: 12px;
  color: #fff;
  background-color: #af1624;
  font-size: 32px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  user-select: none;
}

.btn.close-btn:hover {
  background-color: #739E30;
}

.el-message,
.el-message-box__wrapper {
  z-index: 99999 !important;
}

/*flex modal */
.el-dialog__wrapper.dialog_flex {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.el-dialog__wrapper.dialog_flex .el-dialog {
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  /*max-height: 77%;*/
}
/*
.el-dialog__wrapper.dialog_flex .el-dialog__body > div {
  height: auto;
}*/

.el-dialog__wrapper.dialog_flex .el-dialog__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow-x: visible;
  overflow-y: auto;
  padding: 10px 20px;
}

.main_flex {
  flex: 1;
  height: 100%;
  overflow: auto;
  min-height: 200px;
}

.el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff;
}

.unselectable {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */      
}

.el-loading-spinner .path {
  stroke: #E1051B;
  stroke-width: 4;
}
</style>
