var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"milieu-panel",staticClass:"milieu-panel",class:{
  'panel-hidden': _vm.hidden,
  'panel-collapse': _vm.collapsed
}},[_c('div',{staticClass:"milieu-panel-inner"},[_c('div',{staticClass:"milieu-panel-top"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.collapsed)?_c('div',{staticClass:"milieu-panel-open",on:{"click":_vm.uncollapse}},[_c('i',{staticClass:"icon-files_green"}),_c('span',[_vm._v(" Description & Enjeux ")])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.collapsed)?_c('div',{staticClass:"milieu-panel-close",on:{"click":_vm.collapse}}):_vm._e()])],1)]),(_vm.milieu)?_c('div',{staticClass:"milieu-panel-main"},[_c('div',{staticClass:"milieu-panel-main-container"},[_c('h1',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.milieu.title)}}),(_vm.logged)?_c('div',{staticClass:"edit-button admin-button",on:{"click":_vm.edit}},[_c('i',{staticClass:"icon-pencil"})]):_vm._e()]),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"description-tab tab",class:{
          active: _vm.tab==='description'
        },on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){_vm.tab='description'}}},[_c('i',{staticClass:"icon-files_green"}),_c('span',[_vm._v(" Description ")])]),_c('div',{staticClass:"enjeux-tab tab",class:{
          active: _vm.tab==='enjeux'
        },on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){_vm.tab='enjeux'}}},[_c('i',{staticClass:"icon-warning"}),_c('span',[_vm._v(" Enjeux ")])])]),_c('div',{staticClass:"tabs-content",staticStyle:{"flex":"1","min-height":"200px"}},[(_vm.tab==='description')?_c('div',{staticClass:"tab-content",domProps:{"innerHTML":_vm._s(_vm.milieu.description)}}):_vm._e(),(_vm.tab==='enjeux')?_c('div',{staticClass:"tab-content",domProps:{"innerHTML":_vm._s(_vm.milieu.enjeux)}}):_vm._e()]),_c('div',{staticClass:"licence_block"},[(_vm.milieu.credits_photo!='')?_c('i',[_vm._v(" Crédits photo: "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.milieu.credits_photo)}}),_vm._v(", ")]):_vm._e(),(_vm.milieu.licence_photo!=0)?_c('i',[_vm._v(" licence: "),_c('a',{attrs:{"target":"_blank","href":(_vm.licences.ref[_vm.milieu.licence_photo])?
          _vm.licences.ref[_vm.milieu.licence_photo].link:''},domProps:{"innerHTML":_vm._s((_vm.licences.ref[_vm.milieu.licence_photo])?
          _vm.licences.ref[_vm.milieu.licence_photo].title:'')}})]):_vm._e()]),_c('div',{staticClass:"licence_block"},[(_vm.milieu.credits_sound!='')?_c('i',[_vm._v("Crédits son: "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.milieu.credits_sound)}}),_vm._v(", ")]):_vm._e(),(_vm.milieu.licence_sound!=0)?_c('i',[_vm._v(" licence: "),_c('a',{attrs:{"target":"_blank","href":(_vm.licences.ref[_vm.milieu.licence_sound])?
          _vm.licences.ref[_vm.milieu.licence_sound].link:''},domProps:{"innerHTML":_vm._s((_vm.licences.ref[_vm.milieu.licence_sound])?
          _vm.licences.ref[_vm.milieu.licence_sound].title:'')}})]):_vm._e()])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }