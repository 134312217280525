<template>
  <div>
    <el-dialog
    ref="upload"
    class="media-manager-dialog dialog_flex double_col"
    title="Media manager"
    @open="onMediaManagerOpen"
    @close="onClose"
    :visible="true"
    width="80%">
    <el-row type="flex">
      <el-col
      :span0="16">
        <el-tabs
        :activeName="activeTab"
        type="border-card"
        @tab-click="onTabClick">
          <el-tab-pane
          name="uploadTab"
          ref="uploadTab"
          label="Upload">
            <el-upload
              class="uploader"
              name="media"
              :data="{
                // target_width: cropTarget.width,
                // target_height: cropTarget.height,
                token: token
              }"
              drag
              :action="uploadUrl"
              :on-preview="handlePreview"
              :on-success="onUploadSuccess"
              :file-list="fileList"
              multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Déposer un fichier ici ou <em>cliquer pour téléverser</em>
              </div>
              <!-- <div class="el-upload__tip" slot="tip">
                jpg/png files with a size less than 500kb
              </div> -->
            </el-upload>
          </el-tab-pane>
          <el-tab-pane
          name="mediaLib"
          ref="mediaLib"
          label="Bibliothéque"
          v-infinite-scroll="loadMoreMedia"
          :infinite-scroll-immediate-check="false"
          infinite-scroll-disabled="loadMoreMediaBusy"
          infinite-scroll-listen-for-event="load-more"
          :infinite-scroll-distance="0.7">
            <div class="mediaLib_container">
              <div
              class="thumbnail"
              :class="{
                focused: m.media_id == selectedFileId
              }"
              @click="onClickThumbnail(m, $event)"
              :key="m.media_id"
              :ref="'media-'+m.media_id"
              :id="'media-'+m.media_id"
              v-for="(m, m_index) in mediaLibList"
              v-if="!type_filter || types_list[m.type]">
                <img v-if="m.type === 'image'"
                :src="m.thumb_url">

                <icon 
                v-else
                name="music"
                :style="{
                  color: '#222'
                }"
                scale="2.5"></icon>
                <div
                v-if="m.selected"
                class="media-select-marker">
                  <icon name="check-square" scale="2"></icon>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col
      v-show="activeTab === 'mediaLib' && selectedFileId > 0"
      :span="8"
      style="padding-left: 10px;">
        <el-card class="option-col">
          <div slot="header" class="clearfix">
            <span>Détail du fichier</span>
          </div>
          <div
          v-if="selectedFileId > 0 && mediaLibRef[selectedFileId]">
           <audio
           :src="baseUrl + mediaLibRef[selectedFileId].file_path"
           style="width: 100%;"
           v-if="mediaLibRef[selectedFileId].type === 'sound'" controls>
              Votre navigateur ne supporte pas les éléments audio
            </audio> 
            <a
            v-else
            target="_blank" 
            :href="baseUrl + mediaLibRef[selectedFileId].file_path">
              <img 
              style="display: inline-block; max-width: 100%; width: 100%;"
              :src="baseUrl + mediaLibRef[selectedFileId].file_path">
            </a>
            <el-button type="primary"
            style="width: 100%; margin: 5px 0;"
            v-if="mediaLibRef[selectedFileId].type === 'image'"
            @click.stop="cropperOpen">Recadrer</el-button>

            <el-button type="danger"
            style="width: 100%; margin: 5px 0;"
            @click.stop="delFile(selectedFileId)">Supprimer</el-button>

            <el-form label-width="80px">
              <el-form-item
              style="margin-bottom: 5px;"
              label="Dimensions">
                <div class="text"
                v-if="mediaLibRef[selectedFileId].type === 'image'"
                v-html="mediaLibRef[selectedFileId].width +' x '+mediaLibRef[selectedFileId].height">
                </div>
              </el-form-item>
              <el-form-item
              style="margin-bottom: 5px;"
              label="Chemin">
                <div class="text"
                v-html="mediaLibRef[selectedFileId].file_path">
                </div>
              </el-form-item>
              <el-form-item
              style="margin-bottom: 5px;"
              label="Poids">
                <div class="text"
                v-html="humanFileSize(mediaLibRef[selectedFileId].size)">
                </div>
              </el-form-item>
              <div 
              v-if="mediaLibRef[selectedFileId].type === 'image'">
                <hr style="height: 1px;
                width: 100%;
                color: #eee;
                margin-bottom: 15px;">
                <el-form-item
                style="margin-bottom: 5px;"
                label="Légende">
                <el-input v-model="mediaLibRef[selectedFileId].legend"></el-input>
                </el-form-item>

                <div class="text"
                style="height: 40px; line-height: 40px;padding: 0 15px;color: #555;">
                Utiliser l'image originale
                  <el-switch
                  style="float: right;
                  display: inline-block;
                  padding: 8px 15px;"
                  v-model="mediaLibRef[selectedFileId].original"
                  :inactive-value="0"
                  :active-value="1">
                  </el-switch>
                </div>
                <el-button type="primary"
                style="width: 100%; margin: 5px 0;"
                @click.stop="saveMediaData">Enregister</el-button>
              </div>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%;"
        @click.stop="submit">Ok</el-button>
      </span>
    </el-dialog>

    <el-dialog
    ref="crop"
    class="dialog_flex cropper_dialog"
    :class="{
      hidden_dialog: hidden_crop_dialog
    }"
    title="Crop image"
    @open="onCropperOpen"
    :append-to-body="true"
    :visible.sync="cropManagerVisible"
    width="80%">
      <el-row style="height: 100%;">
        <el-col class='flex-col'>
          <el-tabs
          :activeName="cropperActiveTab"
          type="border-card"
          @tab-click="onCropperTabClick">
            <el-tab-pane
            v-for="format in formats.list"
            v-if="!format_filter || formats_list[format.name]"
            :key="format.name"
            :name="format.name"
            :label="format.title">
            </el-tab-pane>
          </el-tabs>
          <div class="cropper-wrap">
            <vue-cropper
            ref='cropper'
            alt="Source Image"
            :ready="onCropperReady"
            :guides="true"
            :view-mode="2"
            drag-mode="crop"
            :auto-crop-area="0.5"
            :min-container-width="300"
            :min-container-height="300"
            :background="true"
            :rotatable="false"
            :movable="false"
            :scalable="false"
            :zoomable="false"
            :aspect-ratio="aspectRatio"
            :cropend="onCropend"
            :img-style="{ 'height': '100%' }">
            </vue-cropper>
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary"
        style="width: 100%;"
        @click.stop="onCropValid">Ok</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import VueCropper from 'vue-cropperjs'
import VueCropper from '@/components/VueCropper.js'
import Smartcrop from 'smartcrop'
import { EventBus } from '@/components/event_bus.js'

export default {
  // directives: {infiniteScroll},
  name: 'media-manager',
  props: {
    formats: {
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    },
    format_filter: {
      type: Boolean,
      default: false
    },
    type_filter: {
      type: Boolean,
      default: false
    },
    formats_list: {
      type: Object
    },
    types_list: {
      type: Object
    },
    current_file_id: {
      type: Number,
      default: 0
    },
    current_milieu_id: {
      type: Number,
      default: 0
    },
    current_point_id: {
      type: Number,
      default: 0
    },
    current_milieu_categorie_id: {
      type: Number,
      default: 0
    },
    multi_select: {
      type: Boolean,
      default: false
    },
  },
  watch: {

  },
  data () {
    return {
      baseUrl: global.baseUrl,
      // activeTab: "uploadTab",
      activeTab: "mediaLib",
      uploadUrl: global.ajaxAdminUrl + "?action=upload",
      // baseUrl: baseUrl,
      mediaManagerVisible: false,
      cropManagerVisible: false,
      mediaLibEndReached: false,
      loadMoreMediaBusy: false,
      mediaLibRef: {},
      mediaLibList: [],
      mediaLibPage: 0,
      mediaLibNum: 40,
      cropFileId: 0,
      cropTarget: {
        width: 300,
        height: 300
      },
      fileList: [],
      selectedFileId: 0,
      selectedFilesId: [],
      // selectedTab: 0,
      hidden_crop_dialog: false,
      cropperActiveTab: '',
      aspectRatio: 1,
      media_id: 0,
      token: global.token
    }
  },
  mounted () {
    var vm = this

    this.token = global.token

    this.mediaLibLoad()

    // console.log('formats', this.formats)

    this.selectedFileId = this.current_file_id

    // console.log('current_file_id', this.selectedFileId, this.current_file_id)
    
    if(this.formats.list.length > 0) {
      if(this.format_filter){
        var format_name
        for(var i=0, l=this.formats.list.length; i<l; i++) {
          format_name = this.formats.list[i].name
          if(this.formats_list[format_name]){
            this.cropperActiveTab = format_name
            // this.setCropperFormat(format_name)
            break
          }
        }
      }
      else {
        this.cropperActiveTab = this.formats.list[0].name

        var tab_data = this.formats.ref[this.cropperActiveTab]
        this.aspectRatio = tab_data.width/tab_data.height
        this.aspectRatioCalculated = false
      }
    }
  },
  methods: {
    onClose: function() {
      this.$emit('close')
      EventBus.$emit('mediamanager_end', null)
    },
    open: function() {
      this.mediaManagerVisible = true
    },
    close: function() {
      this.mediaManagerVisible = false
    },
    resetMediaLib: function() {
      this.mediaLibEndReached = false
      this.loadMoreMediaBusy = false
      this.mediaLibList = []
      this.mediaLibRef = {}
    },
    mediaLibLoad: function() {
      var vm = this
      this.loadMoreMediaBusy = true

      var req = {
        token: global.token,
        page: vm.mediaLibPage,
        num: vm.mediaLibNum,
        token: global.token,
        // current_file_id: (vm.mediaLibPage===0)?vm.current_file_id:0
        current_file_id: vm.current_file_id
      }

      // if(vm.rando_id>0) {
      //   req.rando_id = vm.rando_id
      // }

      // console.log('milieu/point', vm.current_milieu_id, vm.current_point_id)

      var file_id = Number(vm.current_file_id)
      var milieu_id = Number(vm.current_milieu_id)
      var point_id = Number(vm.current_point_id)
      var milieu_categorie_id = Number(vm.current_milieu_categorie_id)

      if(milieu_id>0) {
        req.milieu_id = milieu_id
      }

      if(point_id>0) {
        req.point_id = point_id
      }

      if(file_id>0) {
        req.current_file_id = file_id
      }

      if(milieu_categorie_id>0) {
        req.milieu_categorie_id = milieu_categorie_id
      }
      
      if(vm.media_id>0) {
        req.media_id = vm.media_id
      }

      if(this.type_filter) {
        req.filtered = 1
        req.filter_type = Object.keys(this.types_list)[0]
      }

      this.$http.post(
        global.ajaxAdminUrl+"?action=get_media_lib",
        req, 
        {

        })
      .then(function(data){
        var resp = data.body

        if(!resp.error) {
          resp.media.forEach(function(m){
            // var thumb_size = vm.formats.ref['thumbnail']
            m.thumb_url = global.baseUrl + global.getThumbFormat(m.file_path)
            // m.selected = !!m.selected
            if(m.selected){
              vm.selectedFilesId.push(m.media_id)
            }
            vm.mediaLibList.push(m)
            vm.mediaLibRef[m.media_id] = m
          })

          if(vm.selectedFilesId.length>0){
            vm.selectedFileId = vm.selectedFilesId[vm.selectedFilesId.length-1]
          }

          vm.mediaLibPage++
          // vm.current_file_id = 0

          if(resp.media.length < vm.mediaLibNum) {
            vm.mediaLibEndReached = true
          }
          else {
            vm.loadMoreMediaBusy = false
          }
        }
      },
      function(data){

      })
    },
    saveMediaData: function() {
      var media = this.mediaLibRef[this.selectedFileId]

      console.log('save media', media)

      this.$http.post(
        global.ajaxAdminUrl+"?action=save_media_data", {
          token: global.token,
          media_id: media.media_id,
          original: media.original,
          legend: media.legend,
        })
      .then(function(data){
        this.$message({
          message: 'Données du media sauvegardées',
          type: 'success'
        })
      },function(data){

      })
    },
    onMediaManagerOpen: function() {

    },
    loadMoreMedia: function() {
      if(!this.mediaLibEndReached){
        this.mediaLibLoad()
      }
    },
    delFile: function(file_id){
      console.log('file_id', file_id)
      var vm = this
      this.$confirm('Êtes-vous sûr de vouloir supprimer le fichier?', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
      })
      .then(function(){
        // vm.deleteRiver(vm.deleteRiverId)
        vm.$http.post(
          global.ajaxAdminUrl+"?action=del_media", {
            token: global.token,
            file_id: file_id
          }, 
          {

          })
        .then(function(data){
          var media = vm.mediaLibRef[file_id]
          media.selected = false
          media.focused = false
          var list_index = vm.mediaLibList.indexOf(media)
          vm.mediaLibList.splice(list_index, 1)
          var list_index = vm.selectedFilesId.indexOf(file_id)
          vm.selectedFilesId.splice(list_index, 1)

          // delete vm.mediaLibRef[file_id]
          vm.mediaLibRef[file_id] = null
          vm.selectedFileId = 0
        },
        function(data){

        })
      })
      .catch(function(){
        // alert('done');
      });
      // del_media
      // file_id
    },
    onCropperTabClick: function(tab){
      // this.setCropper(tab.name)
      // this.cropperActiveTab = tab.name
      // console.log('onCropperTabClick')
      this.setCropperFormat(tab.name)
      this.setSmartCrop()
    },
    setCropperFormat: function(format_name){
      if(format_name !== '') {
        this.cropperActiveTab = format_name
        var format_data = this.formats.ref[this.cropperActiveTab]
        this.aspectRatio = format_data.width/format_data.height
        console.log('format_name', format_name, format_data)
        // console.log('this.$refs.cropper setCropperFormat')
        this.$refs.cropper.setAspectRatio(this.aspectRatio)

      }
    },
    cropperOpen: function(){
      this.cropManagerVisible = true
      // this.hidden_crop_dialog = true

      this.$nextTick(function(){
        var cropper = this.$refs.cropper
        console.log('this.$refs.cropper cropperOpen')
        var media = this.mediaLibRef[this.selectedFileId]
        this.cropFileId = this.selectedFileId
        this.setCropperFormat(this.cropperActiveTab)
        cropper.replace(global.baseUrl + media.file_path)
        cropper.setAspectRatio(this.aspectRatio)

        this.setSmartCrop()
      })
    },
    setSmartCrop: function() {
      var tab_data = this.formats.ref[this.cropperActiveTab]
      var vm = this
      var cropper = this.$refs.cropper
      console.log('this.$refs.cropper setSmartCrop')
      var img = cropper.$refs.img
      // console.log('cropper img', img)
      var newImg = new Image()
      newImg.setAttribute('crossorigin', 'anonymous')
      newImg
      .addEventListener('load', function(){
        vm.$nextTick(function(){
          var options  = {
            width: tab_data.width,
            height: tab_data.height,
            minScale: 1,
            ruleOfThirds: true,
            debug: true
          }

          Smartcrop.crop(newImg, options)
          .then(function(result){
            // console.log('Smartcrop', result.topCrop)
            cropper.setData(result.topCrop)
            vm.hidden_crop_dialog = false
          },
          function(result){
            console.log('errror', result);
          })
        })
      })
      newImg.setAttribute('src', img.src)
    },
    onCropperReady: function() {
      this.setSmartCrop()
    },
    onCropperOpen: function() {

    },
    onCropValid: function() {
      var vm = this
      var cropper = this.$refs.cropper
      console.log('this.$refs.cropper onCropValid')
      var crop_data = cropper.getData(true)
      var format_data = this.formats.ref[this.cropperActiveTab]
      this.cropTarget.width = format_data.width
      this.cropTarget.height = format_data.height
      // console.log('format_data', format_data)
      this.$http.post(
        global.ajaxAdminUrl+"?action=crop", {
          token: global.token,
          file_id: this.cropFileId,
          crop_data: crop_data,
          crop_target: this.cropTarget
        }, 
        {
          // emulateJSON: false
        })
      .then(function(data){
          var resp = data.body
          var point_type
          var media = vm.mediaLibRef[this.cropFileId]
          var thumb_url = media.thumb_url.split('?')[0]
          media.thumb_url = thumb_url + '?' + new Date().getTime()

          this.cropManagerVisible = false

        }, function(data){

      });
    },
    // getThumbFormat: function(url, w, h) {
    //   var tmp = url.split('.')
    //   tmp[tmp.length-2] += '-'+w+'x'+h;
    //   return tmp.join('.')
    // },
    // getThumbUrl: function(url) {
    //   var tmp = url.split('.')
    //   tmp[tmp.length-2] += '-'+this.cropTarget.width+'x'+this.cropTarget.height;
    //   return tmp.join('.')
    // },
    onClickThumbnail: function(m, e){
      var vm = this
      var file_path = m.file_path
      var media_id = Number(m.media_id)
      var media = m

      console.log('media', m)

      if(!media) {
        console.log('no media')
        return

      }
      // console.log('media_id', media_id)

      if(vm.multi_select){
        // media = vm.mediaLibRef[media_id]

        if(media && e.ctrlKey) {

          if(media.selected) {
            if(vm.selectedFileId == media_id) {
              if(vm.selectedFilesId.length>0){
                vm.selectedFileId = vm.selectedFilesId[vm.selectedFilesId.length-1]
              }
              else {
                vm.selectedFileId = 0
              }
            }

            var index = vm.selectedFilesId.indexOf(media_id)
            if (index > -1) {
              vm.selectedFilesId.splice(index, 1)
            }

            media.selected = false
            // media.focused = false
          }
          else {
            vm.selectedFileId = media_id
            vm.selectedFilesId.push(media_id)
            media.selected = true
            // media.focused = true
          }
        }
        else {
          if(media.selected) {
              vm.selectedFileId = media_id
            }
            else {
              vm.selectedFilesId.forEach(function(media_id){
                var media = vm.mediaLibRef[media_id]
                if(media)
                  media.selected = false
              })

              vm.selectedFilesId = []
              vm.selectedFilesId.push(media_id)
              media.selected = true
              vm.selectedFileId = media_id
            }
        }

      }
      else {
        if(media.selected) {
          media.selected = false
          vm.selectedFileId = 0
        }
        else {
          if(this.selectedFileId > 0) {
            var prev_media = vm.mediaLibRef[this.selectedFileId]
            // media = this.mediaLibRef[this.selectedFileId]
            if(prev_media)
            prev_media.selected = false
          }

          this.selectedFileId = media_id
          // media = this.mediaLibRef[this.selectedFileId]
          media.selected = true
        }
      }
    },
    onTabClick: function(tab){
      var vm = this
      // this.selectedTab = Number(tab.index)
      // console.log('tab', tab)
      this.activeTab = tab.name
    },
    onCropend: function(){
      var cropper = this.$refs.cropper
      console.log('this.$refs.cropper onCropend')
      // console.log(cropper)
      var cropData = cropper.getData()
      // console.log(cropData)
    },
    handlePreview: function(){
      console.log('handlePreview')
    },
    handleRemove: function(){
      console.log('handleRemove')
    },
    onUploadSuccess: function(response, file, fileList){
      var vm = this
      // console.log('onUploadSuccess', response, file, fileList)

      if(response.error === false) {
        var item = {
          id: Number(response.file_id),
          media_id: Number(response.file_id),
          file_path: response.file_path,
          type: response.type,
          mime: response.mime,
          thumb_url: global.baseUrl + response.thumb_path,
          width: response.width,
          height: response.height,
          size: response.size,
          selected: 0
        }

        this.mediaLibList.unshift(item)
        this.mediaLibRef[item.id] = item
        // this.cropManagerVisible = true

        var imgSrc = global.baseUrl + response.file_path

        this.$nextTick(function(){
          // var cropper = this.$refs.cropper
          // this.cropFileId = Number(response.file_id)
          // cropper.replace(imgSrc)

          this.activeTab = 'mediaLib'

          if(this.selectedFileId > 0) {
            this.mediaLibRef[this.selectedFileId].selected = false
          }

          this.selectedFilesId.push(item.id)

          item.selected = true
          // this.selectedFileId = this.cropFileId
          this.selectedFileId = item.id
          // response.file_id
          // m.selected

        }) 
      }
    },
    humanFileSize: function (bytes) {
      var thresh = 1024;
      var unit = 'o'
      if(Math.abs(bytes) < thresh) {
        return bytes + ' '+unit;
      }

      var units = ['k','M','G','T','P','E','Z','Y']
      var u = -1;
      do {
          bytes /= thresh;
          ++u;
      } while(Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1)+' '+units[u]+unit;
    },
    submit: function () {
      var vm = this
      // this.mediaManagerVisible = false

      if(vm.multi_select) {
        var resp = []

        vm.selectedFilesId.forEach(function(media_id){
          var media = vm.mediaLibRef[media_id]
          resp.push(media)
        })
      }
      else {
        var resp = null
        if(vm.selectedFileId>0) {
          resp = vm.mediaLibRef[vm.selectedFileId]
        }
        else {
          resp = 0
        }
      }

      this.$emit('input', resp)
      EventBus.$emit('mediamanager_end', resp)
    },
  }
}
</script>
<style type="text/css">

.el-upload {
  display: block;
}

.el-upload-dragger {
  width: 100%;
}

.media-manager-dialog .el-dialog__wrapper {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.media-manager-dialog .el-dialog {
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.media-manager-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
  padding: 10px 20px;
}

.media-manager-dialog .el-dialog__body > div{
  height: 100%;
  overflow: hidden;
}

.media-manager-dialog .el-dialog__body > div{
  height: 100%;
  overflow: hidden;
}

.media-manager-dialog .el-dialog__body > div.uploader {
  overflow: auto;
}

.cropper-container0 {
  display: none;
}

.media-manager-dialog .mediaLib_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.media-manager-dialog .thumbnail {
  cursor: pointer;
  position: relative;
  margin: 10px;
  width: 150px;
  height: 150px;
  border: 1px solid #dcdcdc;
  /*overflow: hidden;*/
}

.media-manager-dialog .thumbnail.focused:after {
  content: '';
  position: absolute;
  top: -6px;
  right: -6px;
  left: -6px;
  bottom: -6px;
  border: 3px solid #409EFF;
  box-shadow: 0 0 1px #409EFF, 0 0 1px #409EFF inset;
}

.thumbnail0.selected {
  border: 2px solid #409EFF;
}

.media-manager-dialog .thumbnail > img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.media-manager-dialog .thumbnail > svg {
  height: 100%;
  margin: auto;
  display: block;
}

.media-manager-dialog .el-tabs {
  display: flex;
  flex-direction: column;
}

.media-manager-dialog .el-tabs__content {
  flex: 1;
  overflow: hidden;
}

.media-manager-dialog .el-tab-pane {
  height: 100%;
  overflow: auto;
}

.media-manager-dialog .cropper-wrap-box,
.media-manager-dialog .cropper-canvas {
  overflow: visible;
}

.cropper-container {
  min-height: 100%;
}

.media-manager-dialog .el-dialog__body > .el-row {
  overflow: visible !important;
}

.media-manager-dialog .el-tabs,
.media-manager-dialog .el-row > .el-col {
  height: 100%;
}

.option-col {
  height: 100%;
  background: #fff;
  border: 1px solid #d8dce5;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.12),0 0 6px 0 rgba(0,0,0,.04);
  display: flex;
  flex-direction: column;
}

.option-col > .el-card__header{
  padding: 7px 14px;
  background: #F5F7FA;
}

.option-col > .el-card__body{
  overflow: auto;
}

.hidden_dialog {
  visibility: hidden;
}

.cropper_dialog .el-tabs__content {
  display: none;
}

.flex-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cropper-wrap {
  flex: 1;
  overflow: hidden;
}

.cropper-wrap > div {
  height: 100%;
  overflow: hidden;
}

.media-select-marker {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 2em;
  height: 2em;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 4px;
  z-index: 100;
}

.media-select-marker > .fa-icon {
  background: #fff;
  color: #66b1ff;
  margin-top: -5px;
  margin-left: -2px;
}

.media-manager-dialog .el-tabs__content {
  overflow: auto;
  padding: 0;
}

#pane-mediaLib {
  padding: 15px 0;
}

</style>